<template>
    <section>
        <div class="vehicle-dashboard">
            <div class="vehicle-dashboard-container">
                <v-card class="filter-block">
                    <Filters :placeHolder="'Search Vehicle Number'" @get-filter="getFilters"></Filters>
                </v-card>

                <v-list>
                    <v-subheader>
                        <div>
                            <v-chip class="ma-2" label  small outlined
                                >Active Vehicles <b> - {{ active }}</b></v-chip
                            >
                          
                            <v-chip class="ma-2" label  outlined style="margin-left: 10px" small
                                >Deactive Vehicles <b> - {{ deactive }}</b></v-chip
                            >

                          
                            <v-chip style="margin-left: 10px" class="ma-2" small label  >
                                Total <b> - {{ totalCount }} </b>
                            </v-chip>

                        </div>
                        <div>
                            <v-btn small color="#377165" @click="openDialog({}, 'insert')"> Add Vehicle <i class="fas fa-plus-circle"></i></v-btn>
                        </div>
                    </v-subheader>
                    <v-data-table :items="items" :headers="headers" item-value="id" height="calc(100vh - 315px)" hide-default-footer class="sticky-header">
                        <template v-slot:body="{ items, headers }">
                            <tbody>
                                <tr v-for="(vehicle, index) in vehicleList" :key="index">
                                    <td>
                                        <div class="vehicle-details">
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <span class="active-status" :class="{ inactive: vehicle.trash }" v-on="on" v-bind="attrs"></span>
                                                </template>
                                                <span>{{ vehicle.trash ? "inactive" : "active" }}</span>
                                            </v-tooltip>
                                            <div class="details">
                                                <span>{{ vehicle.vehicleNo }}</span>
                                                <span>{{ vehicle.manufacturer }} {{ vehicle.model }}</span>
                                            </div>
                                        </div>
                                    </td>
                                    <td class="driver-column">
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <span v-for="(data, driverindex) in vehicle.drivers" :key="driverindex" class="driver-text" v-bind="attrs" v-on="on">{{ data.name }}<span v-if="driverindex !== vehicle.drivers.length - 1">,</span></span>
                                            </template>
                                            <span v-for="(data, driverindex) in vehicle.drivers" :key="driverindex">{{ data.name }}<span v-if="driverindex !== vehicle.drivers.length - 1">,</span></span>
                                        </v-tooltip>
                                    </td>
                                    <td class="document-column">
                                        <span v-if="vehicle.document.insurance.expdate">
                                            {{ getExpiry(vehicle.document.insurance.expdate) }}
                                            <i class="fas fa-exclamation-triangle" v-if="checkExpiry(vehicle.document.insurance.verified)" :class="{ 'expiry-soon': vehicle.document.insurance.verified === 3 }"></i
                                        ></span>

                                        <span v-else>---</span>
                                    </td>
                                    <td class="document-column">
                                        <span v-if="vehicle.document.rcbook.expdate">
                                            {{ getExpiry(vehicle.document.rcbook.expdate) }}
                                            <i class="fas fa-exclamation-triangle" v-if="checkExpiry(vehicle.document.rcbook.verified)" :class="{ 'expiry-soon': vehicle.document.rcbook.verified === 3 }"></i
                                        ></span>
                                        <span v-else>---</span>
                                    </td>
                                    <td class="document-column">
                                        <span v-if="vehicle.document.pollution.expdate">
                                            {{ getExpiry(vehicle.document.pollution.expdate) }}
                                            <i class="fas fa-exclamation-triangle" v-if="checkExpiry(vehicle.document.pollution.verified)" :class="{ 'expiry-soon': vehicle.document.pollution.verified === 3 }"></i
                                        ></span>
                                        <span v-else>---</span>
                                    </td>
                                    <td class="document-column">
                                        <span v-if="vehicle.document.tax.expdate">
                                            {{ getExpiry(vehicle.document.tax.expdate) }}
                                            <i class="fas fa-exclamation-triangle" v-if="checkExpiry(vehicle.document.tax.verified)" :class="{ 'expiry-soon': vehicle.document.tax.verified === 3 }"></i
                                        ></span>
                                        <span v-else>---</span>
                                    </td>
                                    <td class="document-column">
                                        <span v-if="vehicle.document.carriagepermit.expdate">
                                            {{ getExpiry(vehicle.document.carriagepermit.expdate) }}
                                            <i class="fas fa-exclamation-triangle" v-if="checkExpiry(vehicle.document.carriagepermit.verified)" :class="{ 'expiry-soon': vehicle.document.carriagepermit.verified === 3 }"></i
                                        ></span>
                                        <span v-else>---</span>
                                    </td>
                                    <td class="document-column">
                                        <span v-if="vehicle.document.fitness.expdate">
                                            {{ getExpiry(vehicle.document.fitness.expdate) }}
                                            <i class="fas fa-exclamation-triangle" v-if="checkExpiry(vehicle.document.fitness.verified)" :class="{ 'expiry-soon': vehicle.document.fitness.verified === 3 }"></i
                                        ></span>
                                        <span v-else>---</span>
                                    </td>
                                    <td>
                                        <v-btn x-small color="#4c99af" @click="openDialog(vehicle, 'document')">Documents</v-btn>
                                    </td>

                                    <td class="action-column">
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <i class="fas fa-pencil-alt" @click="openDialog(vehicle, 'edit')" v-on="on" v-bind="attrs"></i>
                                            </template>
                                            <span>Edit</span>
                                        </v-tooltip>
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <i class="fas fa-trash" v-if="!vehicle.trash" @click="openDialog(vehicle, 'statusChange')" v-on="on" v-bind="attrs"></i>
                                                <i class="fas fa-undo" v-else @click="openDialog(vehicle, 'statusChange')" v-on="on" v-bind="attrs"></i>
                                            </template>
                                            <span>{{ vehicle.trash ? "Activate" : "Deactivate" }}</span>
                                        </v-tooltip>
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <i class="fas fa-info-circle" @click="openDialog(vehicle, 'remarks')" v-on="on" v-bind="attrs"></i>
                                            </template>
                                            <span>Remarks</span>
                                        </v-tooltip>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-data-table>

                    <v-pagination v-model="page" :length="parseInt(totalCount / 20) + 1"></v-pagination>
                </v-list>
            </div>

            <v-dialog v-model="dialog" v-if="dialog" :width="dialogWidth">
                <v-card class="card-container">
                    <VehicleInsert v-if="dialogStatus === 'edit'" :vehicleDetails="vehicleDetails" :editStatus="true" @vehicle-updated="updateVehicle" @cancel-insert="closeDialog"></VehicleInsert>

                    <VehicleInsert v-if="dialogStatus === 'insert'" :vehicleDetails="{}" :editStatus="false" @vehicle-updated="updateVehicle" @cancel-insert="closeDialog"></VehicleInsert>

                    <DeactivationModal v-if="dialogStatus === 'statusChange'" :token="vehicleDetails.token" :type="2" @change-status="updateVehicle" @dialog-closed="closeDialog" :title="vehicleDetails.trash ? 'Activate' : 'Deactivate'"></DeactivationModal>

                    <VehicleRemarksList v-if="dialogStatus === 'remarks'" :token="vehicleDetails.token"> </VehicleRemarksList>

                    <VehicleDocument v-if="dialogStatus === 'document'" :vehicleDetails="vehicleDetails" @cancel-insert="closeDialog"></VehicleDocument>
                </v-card>
            </v-dialog>

            <Loading v-if="loading"></Loading>
        </div>
    </section>
</template>

<script>
import Filters from "@/components/common/filter/Filters";
import axios from "@/axios/axios-global";
import moment from "moment";
import VehicleInsert from "../vehicle-insert/VehicleInsert.vue";
import DeactivationModal from "../../modal/DeactivationModal.vue";
import VehicleRemarksList from "../vehicle-remarks-list/VehicleRemarksList.vue";
import VehicleDocument from "../vehicle-document/VehicleDocument.vue";
import Loading from "@/components/common/Loading";
export default {
    name: "VehicleDashboardNew",
    components: { Filters, VehicleInsert, DeactivationModal, VehicleRemarksList, VehicleDocument, Loading },
    data() {
        return {
            page: 1,
            items: [],
            headers: [
                { text: "Vehicle", value: "vehicle" },
                { text: "Drivers", value: "driver" },
                { text: "Insurance Validity", value: "insurance" },
                { text: "RC Validity", value: "rc" },
                { text: "PUC Validity", value: "puc" },
                { text: "Tax Validity", value: "tax" },
                { text: "Permit Validity", value: "permit" },
                { text: "Fitness Validity", value: "permit" },
                { text: "Documents", value: "document" },
                { text: "Actions", value: "action" },
            ],
            filters: "",

            vehicleList: [],
            vehicleDetails: {},
            totalCount: 20,
            dialog: false,
            dialogStatus: "",
            dialogWidth: "1000",
            active: 0,
            deactive: 0,
            loading: false,
        };
    },
    mounted() {
        this.getVehicleList(this.filters);
    },
    watch: {
        page() {
            this.getVehicleList(this.filters);
        },
    },
    methods: {
        getFilters(data) {
            this.filters = data;
            this.getVehicleList(this.filters);
            this.page = 1;
        },
        async getVehicleList(data) {
            try {
                this.loading = true;
                const response = await axios.post("vehicle/list", { search: data.searchValue ? data.searchValue : "", page: this.page, items: this.items, is_pagination: true });
                this.loading = false;
                this.active = response.data.active;
                this.deactive = response.data.inactive;
                this.vehicleList = response.data.list;
                this.totalCount = response.data.total;

            } catch (error) {
                this.loading = false;
            } finally {
                this.loading = false;
            }
        },
        updateVehicle() {
            this.dialog = false;
            this.getVehicleList(this.filters);
        },
        closeDialog() {
            this.dialog = false;
        },
        openDialog(data, status) {
            this.dialog = true;
            this.vehicleDetails = data;
            this.dialogStatus = status;
            if (status === "document") this.dialogWidth = "1200";
            else if (status === "statusChange" || status === "vehicleAdd") this.dialogWidth = "500";
            else this.dialogWidth = "1000";
        },
        getExpiry(date) {
            date = date ? moment(date, "YYYYMMDD").format("DD-MM-YYYY") : "";
            date = date && date != "Invalid date" ? date : "---";
            return date;
        },
        checkExpiry(data) {
            return [3, 4].includes(data) ? true : false;
        },
    },
};
</script>

<style lang="scss" scoped>
.vehicle-dashboard {
    .vehicle-dashboard-container {
        padding: 10px;
        .filter-block {
            padding: 20px;
            background: #fff;
            box-shadow: 0 3px 6px rgba(25, 32, 36, 0.16), 0 -1px 4px rgba(25, 32, 36, 0.04);
            border-radius: 5px;
        }
        ::v-deep .v-list {
            border-radius: 5px !important;
            margin-top: 20px;
            padding: 20px !important;
        }
        .v-subheader {
            width: 100%;
            justify-content: space-between;
        }

        ::v-deep .sticky-header thead {
            position: sticky;
            top: 0;
            background-color: white; /* Match background color to avoid transparency */
            z-index: 10; /* Ensures the header stays above the table content */
        }

        .driver-column {
            max-width: 10px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            //    .driver-text::after {
            //     content: ",";
            //    }
            //    ::v-deep .driver-text:last-child::after {
            //     content: "";
            //    }
            //    display: block;
        }

        .vehicle-details {
            display: flex;
            align-items: center;
            padding: 5px 0;
            ::v-deep .active-status {
                width: 10px;
                height: 10px;
                background: green;
                bottom: 0;
                right: 10px;
                border-radius: 50%;
                margin-right: 10px;
            }
            .inactive {
                background: red;
            }
            .image-block {
                overflow: hidden;
                width: 45px;
                height: 45px;
                position: relative;
                box-sizing: content-box;
                border: #ffffff solid 4px;
                outline: 1px solid #d4d4d4;
                line-height: 1px;
                border-radius: 50%;
                background: #ffffff;
                margin-right: 15px;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            .details {
                display: grid;
            }
        }
        .document-column {
            span {
                font-size: 13px;
            }
            .fas {
                color: rgb(218, 3, 3);
                font-size: 10px;
            }
            .expiry-soon {
                color: #ffbe01;
                stroke: #010101;
            }
        }

        .action-column {
            .fas {
                margin-right: 15px;
                cursor: pointer;
            }
        }

        ::v-deep .v-btn {
            color: white;
            font-weight: 700;
            .fas {
                margin-left: 5px;
            }
        }
    }
}
</style>
