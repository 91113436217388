<template>
 <div class="register-outer-modal">
  <v-card class="register-inner-modal">
   <div class="message-blk">
    <v-alert color="red" dense v-if="vendorStatus === 2"><i class="fas fa-ban"></i>{{ rejectMessage }}</v-alert>
    <v-alert color="blue" dense v-if="vendorStatus === 0"><i class="fas fa-exclamation"></i>Waiting for Approval </v-alert>
   </div>
   <div class="taski-name-blk">ta<span>S</span>ki Service Partner Register Form</div>
   <div class="details-blk">
    <div class="header-blk">
     <h3>Basic Information</h3>
    </div>
    <div class="basic-text-field-blk">
     <div class="text-field-blk">
      <v-text-field
       v-model="name"
       label="Company Name *"
       placeholder="Company Name *"
       outlined
       dense
       :error-messages="nameError"
       @blur="$v.name.$touch()"
       @input="$v.name.$touch()"
       id="name-insert"
      ></v-text-field>
     </div>
     <div class="text-field-blk">
      <v-text-field
       v-model.number="mobile"
       label="Mobile Number *"
       placeholder="Mobile Number *"
       outlined
       dense
       maxlength="10"
       :error-messages="mobileError"
       @blur="$v.mobile.$touch()"
       @input="$v.mobile.$touch()"
       id="number-insert"
      ></v-text-field>
     </div>
     <div class="text-field-blk">
      <v-text-field
       v-model="contactPerson"
       label="Contact Person *"
       placeholder="Contact Person *"
       outlined
       dense
       :error-messages="contactPersonError"
       @blur="$v.contactPerson.$touch()"
       @input="$v.contactPerson.$touch()"
       id="contact-person-insert"
      ></v-text-field>
     </div>
     <div class="text-field-blk">
      <v-text-field
       v-model="alternateNumber"
       label="Alternative Phone Number *"
       placeholder="Alternative Phone Number *"
       outlined
       dense
       maxlength="10"
       :error-messages="alternateNumberError"
       @blur="$v.alternateNumber.$touch()"
       @input="$v.alternateNumber.$touch()"
       id="alternative-number-insert"
      ></v-text-field>
     </div>
     <div class="text-field-blk">
      <v-text-field
       v-model="email"
       label="E-mail *"
       placeholder="E-mail *"
       outlined
       dense
       :error-messages="emailError"
       @blur="$v.email.$touch()"
       @input="$v.email.$touch()"
       id="email-insert"
      ></v-text-field>
     </div>
    </div>
   </div>

   <div class="details-blk">
    <div class="header-blk">
     <h3>Address Details</h3>
    </div>
    <div class="basic-text-field-blk">
     <div class="text-field-blk">
      <v-textarea
       v-model="address"
       label="Address *"
       placeholder="Address *"
       outlined
       dense
       rows="1"
       auto-grow
       :error-messages="addressError"
       @blur="$v.address.$touch()"
       @input="$v.address.$touch()"
       id="address-insert"
      ></v-textarea>
     </div>
     <div class="file-input-blk" id="address-proof-insert">
      <input type="file" class="file-blk" ref="addressProof" @change="selectAddressProof" accept="application/pdf,image/*" />
      <v-btn v-if="!addressProof" class="upload-blk" @click="$refs.addressProof.click()">Upload Address Proof * </v-btn>
      <div v-if="addressProof && oldAddressProof !== addressProof" class="certificate-blk">
       <span class="name-blk">{{ addressProof }}</span>
       <span class="cancel-blk" @click="cancelAddressProof"><i class="fas fa-times"></i></span>
      </div>
      <div class="certificate-view-blk" v-if="addressProof && oldAddressProof === addressProof">
       <a :href="`${baseUrl}${addressProof}`" target="_blank">View Address Proof</a>
       <span @click="cancelAddressProof" v-if="vendorStatus === 2"><i class="fas fa-times"></i></span>
      </div>
     </div>
    </div>
   </div>

   <div class="details-blk">
    <div class="header-blk">
     <h3>Company Incorporation Certificate</h3>
    </div>
    <div class="basic-text-field-blk">
     <div class="text-field-blk">
      <v-text-field
       v-model="cinNo"
       placeholder="Corporate Identification Number *"
       label="Corporate Identification Number *"
       dense
       outlined
       maxlength="21"
       :error-messages="cinNoError"
       @blur="$v.cinNo.$touch()"
       @input="$v.cinNo.$touch()"
       id="cin-no-insert"
      >
      </v-text-field>
     </div>
     <div class="file-input-blk" id="certificate-proof-insert">
      <input type="file" class="file-blk" ref="certificateProof" @change="selectCertificate" accept="application/pdf,image/*" />
      <v-btn v-if="!certificateProof" class="upload-blk" @click="$refs.certificateProof.click()">Upload Certificate * </v-btn>
      <div v-if="certificateProof && certificateProof !== oldCertificateProof" class="certificate-blk">
       <span class="name-blk">{{ certificateProof }}</span>
       <span class="cancel-blk" @click="cancelCertificateProof"><i class="fas fa-times"></i></span>
      </div>
      <div class="certificate-view-blk" v-if="certificateProof && oldCertificateProof === certificateProof">
       <a :href="`${baseUrl}${certificateProof}`" target="_blank">View Incorporate Certificate</a>
       <span @click="cancelCertificateProof" v-if="vendorStatus === 2"><i class="fas fa-times"></i></span>
      </div>
     </div>
    </div>
   </div>

   <div class="details-blk">
    <div class="header-blk">
     <h3>Pan Details</h3>
    </div>
    <div class="basic-text-field-blk">
     <div class="text-field-blk">
      <v-text-field v-model="panNo" placeholder="Pan Number *" label="Pan Number *" dense outlined :error-messages="panNoError" @blur="$v.panNo.$touch()" @input="$v.panNo.$touch()" id="pan-no-insert">
      </v-text-field>
     </div>
     <div class="file-input-blk" id="pan-proof-insert">
      <input type="file" class="file-blk" ref="panProof" @change="selectPanProof" accept="application/pdf,image/*" />
      <v-btn v-if="!panProof" class="upload-blk" @click="$refs.panProof.click()"> Upload Pan Card * </v-btn>
      <!--            <span v-if="panProof === '' && vendorStatus !== 2" class="no-upload-blk">No File Uploaded</span>-->
      <div v-if="panProof && panProof !== oldPanProof" class="certificate-blk">
       <span class="name-blk">{{ panProof }}</span>
       <span class="cancel-blk" @click="cancelPanSelect"><i class="fas fa-times"></i></span>
      </div>
      <div class="certificate-view-blk" v-if="panProof && oldPanProof === panProof">
       <a :href="`${baseUrl}${panProof}`" target="_blank">View Pan Card</a>
       <span @click="cancelPanSelect" v-if="vendorStatus === 2"><i class="fas fa-times"></i></span>
      </div>
     </div>
    </div>
   </div>

   <div class="details-blk">
    <div class="header-blk">
     <h3>GST Details</h3>
    </div>
    <div class="basic-text-field-blk">
     <div class="text-field-blk">
      <v-text-field
       v-model="gstNo"
       label="GST Number *"
       placeholder="GST Number *"
       outlined
       dense
       :error-messages="gstNoError"
       @blur="$v.gstNo.$touch()"
       @input="$v.gstNo.$touch()"
       maxlength="15"
       id="gst-no-insert"
      ></v-text-field>
     </div>
     <div class="file-input-blk" id="gst-proof-insert">
      <input type="file" class="file-blk" ref="gstProof" @change="selectGst" accept="application/pdf,image/*" />
      <v-btn v-if="!gstDocument" class="upload-blk" @click="$refs.gstProof.click()">Upload GST Certificate * </v-btn>
      <div v-if="gstDocument && gstDocument !== oldGstDocument" class="certificate-blk">
       <span class="name-blk">{{ gstDocument }}</span>
       <span class="cancel-blk" @click="cancelGstProof"><i class="fas fa-times"></i></span>
      </div>
      <div class="certificate-view-blk" v-if="gstDocument && oldGstDocument === gstDocument">
       <a :href="`${baseUrl}${gstDocument}`" target="_blank">View GST Certificate</a>
       <span @click="cancelGstProof" v-if="vendorStatus === 2"><i class="fas fa-times"></i></span>
      </div>
     </div>
    </div>
   </div>
   <div class="details-blk">
    <div class="header-blk">
     <h3>Bank Details</h3>
    </div>
    <div class="basic-text-field-blk">
     <div class="text-field-blk">
      <v-text-field
       v-model="accountNo"
       label="Account Number *"
       placeholder="Account Number *"
       outlined
       dense
       :error-messages="accountNoError"
       @blur="$v.accountNo.$touch()"
       @input="$v.accountNo.$touch()"
       maxlength="19"
       id="account-number-insert"
      ></v-text-field>
     </div>
     <div class="text-field-blk">
      <v-text-field
       v-model="ifscCode"
       label="IFSC Code *"
       placeholder="IFSC Code *"
       outlined
       dense
       :error-messages="ifscCodeError"
       @blur="$v.ifscCode.$touch()"
       @input="selectBranch"
       maxlength="11"
       id="ifsc-code-insert"
      ></v-text-field>
     </div>
     <div class="text-field-blk">
      <v-text-field v-model="branch" label="Branch Name *" placeholder="Branch Name *" outlined dense disabled :error-messages="branchError" id="branch-insert"></v-text-field>
     </div>
     <div class="file-input-blk" id="bank-proof-insert">
      <input type="file" class="file-blk" ref="bankProof" @change="selectBankProof" accept="application/pdf,image/*" />
      <!--            <v-btn  v-if="bankProof === ''">Choose</v-btn>-->
      <v-btn v-if="!bankProof" class="upload-blk" @click="$refs.bankProof.click()">Upload Passbook *</v-btn>
      <div v-if="bankProof && bankProof !== oldBankProof" class="certificate-blk">
       <span class="name-blk">{{ bankProof }}</span>
       <span class="cancel-blk" @click="cancelBankProof"><i class="fas fa-times"></i></span>
      </div>
      <div class="certificate-view-blk" v-if="bankProof && oldBankProof === bankProof">
       <a :href="`${baseUrl}${bankProof}`" target="_blank">View Bank Passbook</a>
       <span @click="cancelBankProof" v-if="vendorStatus === 2"><i class="fas fa-times"></i></span>
      </div>
     </div>
    </div>
   </div>

   <div class="register-button-blk">
    <v-btn color="#376e9d" @click="registerData">Update</v-btn>
    <v-btn color="#9d3737" @click="clearData">Clear</v-btn>
   </div>
  </v-card>

  <v-dialog v-model="dialogView" persistent width="400">
   <v-card class="dialog-blk">
    <span>{{ dialogMessage }}</span>
    <div class="dialog-button-blk">
     <v-btn color="#f4c736" small @click="dialogView = false">Ok</v-btn>
    </div>
   </v-card>
  </v-dialog>
 </div>
</template>

<script>
import { email, minLength, numeric, required } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import axiosGet from "axios";
import axios from "@/axios/axios-global";
import instance from "@/axios/axios-global";

export default {
 name: "Register",
 mixins: [validationMixin],
 validations() {
  return {
   name: { required },
   mobile: { required, minLength: minLength(10), numeric },
   contactPerson: { required },
   alternateNumber: { required, minLength: minLength(10), numeric },
   email: { required, email },
   address: { required },
   gstNo: { required },
   panNo: { required },
   panProof: { required },
   cinNo: { required },
   accountNo: { required },
   ifscCode: { required },
   addressProof: { required },
   gstDocument: { required },
   bankProof: { required },
   certificateProof: { required },
   branch: { required },
   vendorDetails:null
  };
 },
 watch: {
  gstNo() {
   if (this.gstNo) {
    this.gstNo = this.gstNo.toUpperCase();
   }
  },
  ifscCode() {
   if (this.ifscCode) {
    this.ifscCode = this.ifscCode.toUpperCase();
   }
  },
 },
 data: () => {
  return {
   name: "",
   mobile: "",
   contactPerson: "",
   alternateNumber: "",
   email: "",
   address: "",
   addressProof: "",

   panNo: "",
   panProof: "",

   gstNo: "",
   gstDocument: "",

   accountNo: "",
   ifscCode: "",
   bankProof: "",
   branch: "",

   certificateProof: "",
   cinNo: "",

   addressFile: "",
   gstFile: "",
   bankFile: "",
   certificateFile: "",
   panFile: "",

   vendorData: "",
   rejectMessage: "",

   vendorStatus: "",

   baseUrl: "https://taski2020.s3.ap-south-1.amazonaws.com/",

   oldAddressProof: "",
   oldGstDocument: "",
   oldBankProof: "",
   oldCertificateProof: "",
   oldPanProof: "",

   dialogView: false,
   dialogMessage: "",
  };
 },
 async mounted() {
  await this.getUserData();
  // this.vendorStatus = localStorage.getItem('vendorStatus')
  if (this.ifscCode) {
   await this.selectBranch();
  }
 },
 methods: {
  async getUserData() {
   const response = await axios.get("/admin/details");
   let vendorData = response.data;
   this.vendorDetails=response.data
   this.address = vendorData.address;
   this.name = vendorData.name;
   this.mobile = vendorData.mobile;
   this.contactPerson = vendorData.details.contactPerson;
   this.alternateNumber = vendorData.details.additionalContact;
   this.email = vendorData.details.email;
   this.gstNo = vendorData.gst.gstNo;
   this.accountNo = vendorData.bankDetails.accountNo;
   this.ifscCode = vendorData.bankDetails.ifsc;
   this.rejectMessage = vendorData.reason;
   this.addressProof = vendorData.documents.addressProof;
   this.oldAddressProof = vendorData.documents.addressProof;
   this.gstDocument = vendorData.gst.gstCertificate;
   this.oldGstDocument = vendorData.gst.gstCertificate;
   this.bankProof = vendorData.bankDetails.bankDocument;
   this.oldBankProof = vendorData.bankDetails.bankDocument;
   this.certificateProof = vendorData.documents.inCorporationCertificate ? vendorData.documents.inCorporationCertificate.inCorporationCertificate : undefined;
   this.oldCertificateProof = vendorData.documents.inCorporationCertificate ? vendorData.documents.inCorporationCertificate.inCorporationCertificate : undefined;
   this.panNo = vendorData.documents.pan ? vendorData.documents.pan.panNo : undefined;
   this.panProof = vendorData.documents.pan ? vendorData.documents.pan.panCertificate : undefined;
   this.oldPanProof = vendorData.documents.pan ? vendorData.documents.pan.panCertificate : undefined;
   this.cinNo = vendorData.documents.inCorporationCertificate ? vendorData.documents.inCorporationCertificate.cinNo : undefined;
   this.vendorStatus = vendorData.status;
  },
  selectAddressProof(event) {
   const files = event.target.files;
   this.addressProof = files[0].name;
   this.addressFile = files[0];
  },
  cancelAddressProof() {
   this.addressProof = "";
   this.addressFile = "";
   this.$refs.addressProof.value = "";
  },
  selectGst(event) {
   const files = event.target.files;
   this.gstDocument = files[0].name;
   this.gstFile = files[0];
  },
  cancelGstProof() {
   this.gstDocument = "";
   this.gstFile = "";
   this.$refs.gstProof.value = "";
  },
  selectBankProof(event) {
   const files = event.target.files;
   this.bankProof = files[0].name;
   this.bankFile = files[0];
  },
  cancelBankProof() {
   this.bankProof = "";
   this.bankFile = "";
   this.$refs.bankProof.value = "";
  },
  selectCertificate(event) {
   const files = event.target.files;
   this.certificateProof = files[0].name;
   this.certificateFile = files[0];
  },
  cancelCertificateProof() {
   this.certificateProof = "";
   this.certificateFile = "";
   this.$refs.certificateProof.value = "";
  },
  selectPanProof(event) {
   const files = event.target.files;
   this.panProof = files[0].name;
   this.panFile = files[0];
  },
  cancelPanSelect() {
   this.panProof = "";
   this.panFile = "";
   this.oldPanProof = "";
   this.$refs.panProof.value = "";
  },
  async selectBranch() {
   this.$v.ifscCode.$touch();
   if (this.ifscCode.length > 10) {
    try {
     const response = await axiosGet.get(`https://ifsc.razorpay.com/${this.ifscCode}`);
     if (response.status === 200) {
      this.branch = response.data.BRANCH;
     }
    } catch (error) {
     this.$toasted.show("Invalid IFSC Code", {
      position: "bottom-center",
      duration: 4000,
     });
     this.branch = "";
    }
   } else {
    this.branch = "";
   }
  },
  async registerData() {
   // this.$v.$touch()
   // if (this.$v.$anyError) {
   //   if (this.$v.name.$anyError) {
   //     document.getElementById('name-insert').scrollIntoView({behavior: 'smooth'});
   //     document.getElementById('name-insert').focus()
   //   } else if (this.$v.mobile.$anyError) {
   //     document.getElementById('number-insert').scrollIntoView({behavior: 'smooth'});
   //     document.getElementById('number-insert').focus()
   //   } else if (this.$v.contactPerson.$anyError) {
   //     document.getElementById('contact-person-insert').scrollIntoView({behavior: 'smooth'});
   //     document.getElementById('contact-person-insert').focus()
   //   } else if (this.$v.alternateNumber.$anyError) {
   //     document.getElementById('alternative-number-insert').scrollIntoView({behavior: 'smooth'});
   //     document.getElementById('alternative-number-insert').focus()
   //   } else if (this.$v.email.$anyError) {
   //     document.getElementById('email-insert').scrollIntoView({behavior: 'smooth'});
   //     document.getElementById('email-insert').focus()
   //   } else if (this.$v.address.$anyError) {
   //     document.getElementById('address-insert').scrollIntoView({behavior: 'smooth'});
   //     document.getElementById('address-insert').focus()
   //   } else if (this.$v.cinNo.$anyError) {
   //     document.getElementById('cin-no-insert').scrollIntoView({behavior: 'smooth'});
   //     document.getElementById('cin-no-insert').focus()
   //   } else if (this.$v.gstNo.$anyError) {
   //     document.getElementById('gst-no-insert').scrollIntoView({behavior: 'smooth'});
   //     document.getElementById('gst-no-insert').focus()
   //   } else if (this.$v.panNo.$anyError) {
   //     document.getElementById('pan-no-insert').scrollIntoView({behavior: 'smooth'});
   //     document.getElementById('pan-no-insert').focus()
   //   } else if (this.$v.accountNo.$anyError) {
   //     document.getElementById('account-number-insert').scrollIntoView({behavior: 'smooth'});
   //     document.getElementById('account-number-insert').focus()
   //   } else if (this.$v.ifscCode.$anyError) {
   //     document.getElementById('ifsc-code-insert').scrollIntoView({behavior: 'smooth'});
   //     document.getElementById('ifsc-code-insert').focus()
   //   } else if (this.$v.branch.$anyError) {
   //     document.getElementById('branch-insert').scrollIntoView({behavior: 'smooth'});
   //   } else if (this.$v.addressProof.$anyError) {
   //     document.getElementById('address-proof-insert').scrollIntoView({behavior: 'smooth'});
   //     this.$toasted.show('Upload Address Proof', {
   //       position: "bottom-center",
   //       duration: 4000
   //     });
   //   } else if (this.$v.certificateProof.$anyError) {
   //     document.getElementById('certificate-proof-insert').scrollIntoView({behavior: 'smooth'});
   //     this.$toasted.show('Upload InCorporate Certificate', {
   //       position: "bottom-center",
   //       duration: 4000
   //     });
   //   } else if (this.$v.gstDocument.$anyError) {
   //     document.getElementById('gst-proof-insert').scrollIntoView({behavior: 'smooth'});
   //     this.$toasted.show('Upload GST Certificate', {
   //       position: "bottom-center",
   //       duration: 4000
   //     });
   //   } else if (this.$v.bankProof.$anyError) {
   //     document.getElementById('bank-proof-insert').scrollIntoView({behavior: 'smooth'});
   //     this.$toasted.show('Upload Passbook/Cancel Cheque', {
   //       position: "bottom-center",
   //       duration: 4000
   //     });
   //   } else if (this.$v.panProof.$anyError) {
   //     document.getElementById('pan-proof-insert').scrollIntoView({behavior: 'smooth'});
   //     this.$toasted.show('Upload Pan', {
   //       position: "bottom-center",
   //       duration: 4000
   //     });
   //   }

   // } else {
   try {
    const payload = {
     id: this.vendorDetails.id,
     token: this.vendorDetails.token,
     name: this.name,
     mobile: this.mobile,
     panNo: this.panNo,
     vendor: this.vendorDetails.vendor,
     details: {
      contactPerson: this.contactPerson,
      email: this.email,
      additionalContact: this.alternateNumber,
     },
     address: this.address,
     corporate: this.vendorDetails.corporate,
     bankDetails: {
      accountNo: this.accountNo,
      ifsc: this.ifscCode,
     },
     gst: { gstNo: this.gstNo },
     cinNo: this.cinNo,
     documents: {
      addressFile: this.addressProof === this.oldAddressProof ? this.addressProof : this.oldAddressProof,
      gstFile: this.gstDocument === this.oldGstDocument ? this.gstDocument : this.oldGstDocument,
      bankFile: this.bankProof === this.oldBankProof ? this.bankProof : this.oldBankProof,
      inCorporateFile: this.certificateProof === this.oldCertificateProof ? this.certificateProof : this.oldCertificateProof,
      panFile: this.panProof === this.oldPanProof ? this.panProof : this.oldPanProof,
     },
    };
    let formData = new FormData();
    if (this.addressProof !== this.oldAddressProof) {
     formData.append("addressProof", this.addressFile);
    }
    if (this.gstDocument !== this.oldGstDocument) {
     formData.append("gstCertificate", this.gstFile);
    }
    if (this.bankProof !== this.oldBankProof) {
     formData.append("passbook", this.bankFile);
    }
    if (this.certificateProof !== this.oldCertificateProof) {
     formData.append("inCorporationCertificate", this.certificateFile);
    }
    if (this.panProof !== this.oldPanProof) {
     formData.append("pan", this.panFile);
    }
    const setencryptUtil =  new this.$encryptUtil({ decrypt: payload });
    const data=setencryptUtil.getEncryptedData()
    formData.append("data", data);
    // formData.append("data", JSON.stringify(payload));
    const response = await axios.post("/admin/resubmit", formData);
    if (response.status === 200) {
     this.dialogMessage = "Successfully Submitted";
     this.dialogView = true;
     await this.getUserData();
    } else if (response.status === 206) {
     this.dialogMessage = response.data;
     this.dialogView = true;
    }
   } catch (error) {
    this.dialogMessage = "Please Try Again";
    this.dialogView = true;
   }
   // }
  },
  clearData() {
   this.name = "";
   this.mobile = "";
   this.contactPerson = "";
   this.email = "";
   this.alternateNumber = "";
   this.addressFile = "";
   this.addressProof = "";
   this.address = "";
   this.accountNo = "";
   this.ifscCode = "";
   this.gstNo = "";
   this.gstFile = "";
   this.gstDocument = "";
   this.bankProof = "";
   this.bankFile = "";
   this.certificateFile = "";
   this.certificateProof = "";
   this.panNo = "";
   this.panProof = "";
   this.panFile = "";
   this.cinNo = "";
   this.$v.$reset();
  },
 },
 computed: {
  nameError() {
   const errors = [];
   if (!this.$v.name.$dirty) return errors;
   !this.$v.name.required && errors.push("Name is required.");
   return errors;
  },
  mobileError() {
   const errors = [];
   if (!this.$v.mobile.$dirty) return errors;
   !this.$v.mobile.required && errors.push("Number is required.");
   !this.$v.mobile.minLength && errors.push("Enter a valid Number");
   !this.$v.mobile.numeric && errors.push("Enter a valid Number");
   return errors;
  },
  contactPersonError() {
   const errors = [];
   if (!this.$v.contactPerson.$dirty) return errors;
   !this.$v.contactPerson.required && errors.push("Contact Person is required.");
   return errors;
  },
  alternateNumberError() {
   const errors = [];
   if (!this.$v.alternateNumber.$dirty) return errors;
   !this.$v.alternateNumber.required && errors.push("Number is required.");
   !this.$v.alternateNumber.minLength && errors.push("Enter a valid Number");
   !this.$v.alternateNumber.numeric && errors.push("Enter a valid Number");
   return errors;
  },
  emailError() {
   const errors = [];
   if (!this.$v.email.$dirty) return errors;
   !this.$v.email.required && errors.push("Email is required.");
   !this.$v.email.email && errors.push("Enter a valid e-mail");
   return errors;
  },
  addressError() {
   const errors = [];
   if (!this.$v.address.$dirty) return errors;
   !this.$v.address.required && errors.push("Address is required.");
   return errors;
  },
  cinNoError() {
   const errors = [];
   if (!this.$v.cinNo.$dirty) return errors;
   !this.$v.cinNo.required && errors.push("CIN is required.");
   return errors;
  },
  panNoError() {
   const errors = [];
   if (!this.$v.panNo.$dirty) return errors;
   !this.$v.panNo.required && errors.push("Pan No is required.");
   return errors;
  },
  gstNoError() {
   const errors = [];
   if (!this.$v.gstNo.$dirty) return errors;
   !this.$v.gstNo.required && errors.push("GST Number is required.");
   return errors;
  },
  accountNoError() {
   const errors = [];
   if (!this.$v.accountNo.$dirty) return errors;
   !this.$v.accountNo.required && errors.push("Account Number is required.");
   return errors;
  },
  ifscCodeError() {
   const errors = [];
   if (!this.$v.ifscCode.$dirty) return errors;
   !this.$v.ifscCode.required && errors.push("IFSC Code is required.");
   return errors;
  },
  branchError() {
   const errors = [];
   if (!this.$v.branch.$dirty) return errors;
   !this.$v.branch.required && errors.push("Branch Name is required.");
   return errors;
  },
 },
};
</script>

<style scoped lang="scss">
.register-outer-modal {
 width: 100%;
 padding: 20px;
 display: flex;
 justify-content: center;
 .register-inner-modal {
  width: 60%;
  padding: 10px 20px;

  .taski-name-blk {
   font-size: 25px;
   margin: 10px 0;
   font-weight: bold;
   font-family: Corbel;
   text-align: center;
  }

  .details-blk {
   display: flex;
   flex-direction: column;
  }

  .header-blk {
   padding: 2px;
   border-bottom: 1px solid #d7d7d7;

   h3 {
    font-weight: bolder;
   }
  }

  .basic-text-field-blk {
   display: flex;
   flex-wrap: wrap;
   padding-top: 20px;
   justify-content: space-between;
   align-items: flex-start;

   ::v-deep .v-text-field {
    margin: 0 10px;
    font-size: 13px;
   }

   .text-field-blk {
    width: 47%;
   }

   .file-input-blk {
    width: 45%;
    margin: 0 10px 20px 10px;
    display: flex;
    align-items: center;
    border-radius: 5px;

    .certificate-blk {
     display: flex;
     //border: 1px solid gray;
     width: 100%;

     .name-blk {
      border: 1px solid gray;
      padding: 5px 10px;
      max-width: 90%;
      text-align: left;
     }

     .cancel-blk {
      border: 1px solid gray;
      border-left: none;
      background-color: green;
      color: white;
      padding: 5px;
      width: 10%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      .fas {
       font-size: 16px;
      }
     }
    }

    .certificate-view-blk {
     display: flex;
     margin: 10px;

     span {
      margin-left: 10px;
     }
    }

    .file-blk {
     display: none;
    }

    .upload-blk {
     font-weight: bold;
     margin: 0 10px;
     cursor: pointer;
    }

    .no-upload-blk {
     margin: 10px;
     color: gray;
    }

    button {
     background-color: #5569ee;
     color: white;
    }

    button:hover {
     background-color: #919090;
    }
   }
  }

  .register-button-blk {
   display: flex;
   justify-content: center;
   position: sticky;
   bottom: 0;

   .v-btn {
    font-weight: bold;
    color: white;
    margin: 0 10px;
   }
  }
 }
}

.message-blk {
 width: 100%;

 .v-sheet.v-alert {
  color: white;
  font-family: Corbel;

  .fas {
   margin-right: 5px;
  }
 }
}

::v-deep .v-text-field .v-label {
 font-size: 14px;
}

.dialog-blk {
 padding: 15px;
 font-family: "Muli", sans-serif;

 span {
  font-weight: bold;
 }

 .dialog-button-blk {
  display: flex;
  flex-direction: row-reverse;
  margin-top: 20px;

  .v-btn {
   width: 100px;
  }
 }
}
</style>
