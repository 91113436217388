<template>
  <div class="reports-outer">
    <div class="content-maininnerblk">


      <div class="bottom-block">

        <div class="half-block">

          <div class="widget">
            <div class="widget-body">
              <div class="content-panel">

                <div class="inner-forms-block">
                  <div class="single-row">
                    <v-menu
                        v-model="menu1"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="dateComputed"
                            label="Date Range"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            hide-details
                        ></v-text-field>
                      </template>
                      <v-date-picker
                          v-model="dateRange"
                          color="black"
                          :max="startMax"
                          range
                      ></v-date-picker>
                    </v-menu>

                  </div>

<!--                  <div class="single-row">-->
<!--                    <v-menu-->
<!--                        v-model="menu2"-->
<!--                        :close-on-content-click="false"-->
<!--                        :nudge-right="40"-->
<!--                        transition="scale-transition"-->
<!--                        offset-y-->
<!--                        min-width="290px"-->
<!--                    >-->
<!--                      <template v-slot:activator="{ on, attrs }">-->
<!--                        <v-text-field-->
<!--                            v-model="endDateComputed"-->
<!--                            label="End Date"-->
<!--                            readonly-->
<!--                            v-bind="attrs"-->
<!--                            v-on="on"-->
<!--                            hide-details-->
<!--                        ></v-text-field>-->
<!--                      </template>-->
<!--                      <v-date-picker-->
<!--                          v-model="endDate"-->
<!--                          color="black"-->
<!--                          @input="menu2 = false"-->
<!--                          :max="endMaxDateComputed"-->
<!--                          :min="startDate"-->
<!--                      ></v-date-picker>-->
<!--                    </v-menu>-->

<!--                  </div>-->
                  <div class="single-row">
                    <v-autocomplete
                        v-model="formData.department"
                        :items="departments"
                        label="Department"
                        color="#ff9600"
                        item-text="name"
                        item-value="id"
                        hide-details
                    >

                    </v-autocomplete>

                  </div>

                  <div class="single-row" v-if="filtersData !== undefined">

                    <v-autocomplete
                        v-model="formData.branch"
                        :items="branchList"
                        label="Branch"
                        color="#ff9600"
                        item-text="name"
                        item-value="id"
                        hide-details
                    ></v-autocomplete>

                  </div>
                  <div class="single-row" v-if="filtersData !== undefined">

                    <v-autocomplete
                        v-model="reportType"
                        :items="reportTypes"
                        label="Trip Type"
                        color="#ff9600"
                        item-text="name"
                        item-value="id"
                        hide-details
                    ></v-autocomplete>

                  </div>
                  <div class="single-row" v-if="filtersData !== undefined">
                    <v-btn color="#ff9600" @click="submitReport()">Get Report</v-btn>
                  </div>

                  <div class="single-row" v-if="totalRows > 0">
                    <v-btn color="#034b26" @click="exportReport">Excel Export</v-btn>
                  </div>
                </div>
              </div>
            </div>
          </div>


        </div>




<!--        <div class="half-block">-->


<!--          <div class="content-panel">-->

<!--          </div>-->
<!--        </div>-->



      </div>
      <v-container fluid v-if="totalRows > 0">
        <v-row no-gutters>
          <v-col cols="12" md="12">
            <div class="report-content-column">
              <v-data-table
                  :headers="headers"
                  :items="formatReportData"
                  class="elevation-1"
                  :options.sync="options"
                  :server-items-length="totalRows"
                  :footer-props="{itemsPerPageOptions:options.itemsPerPageOptions}"
                  fixed-header
                  height="calc(100vh - 300px)"
                  v-if="formatReportData.length > 0"
              >
 

              </v-data-table>


            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>


    <v-dialog
        v-model="reportDialog"
        max-width="450px"
        justify="center"
        content-class="report-generate-message"
    >
      <v-card justify="center">
        <v-card-title class="text-center centered-title">Report Status</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <div>
            <h4 v-if="dialogMessage !== ''">Report will be ready shortly. </h4>
            <h4 v-else>{{dialogMessage}}</h4>

            <div class="report-loader" v-if="reportLoading && dialogMessage !== ''">
              <v-progress-circular
                  :width="5"
                  :size="50"
                  color="red"
                  indeterminate
              ></v-progress-circular>
            </div>

            <p v-if="dialogMessage !== ''">Please contact taSki if there are issues to generate reports.</p>

          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn color="blue darken-1" text @click="reportDialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>


    </v-dialog>
  </div>
</template>

<script>

    import axios from '../../axios/axios-global';
    import moment from "moment";
    import VueExcel from "@/components/common/VueExcel";

    export default {
        name: "PeriodicalReports",
        data: () => ({
            menu: false,
            modal: false,
            menu1: false,
            menu2: false,
            menu3: false,
            menu4: false,
            dateRange: [moment().format('YYYY-MM-DD'),moment().format('YYYY-MM-DD')],
            startDate: moment().format('YYYY-MM-DD'),
            endDate: moment().format('YYYY-MM-DD'),
            formData: {startdate: -1, enddate: -1, branch: -1, reporttype: -1},
            filtersData: undefined,
            reportTypes: [
                {"id": 1, "name": "Ended Trips"},
                {"id": 2, "name": "Rejected Trips"},
                {"id": 3, "name": "Cancelled Trips"},
            ],
            reportType: 1,
            reportDialog: false,
            reportLoading: true,
            reportDialogMessage: "",
            today: moment().format("YYYY-MM-DD"),
            startMax: moment().format("YYYY-MM-DD"),
            endMin: "",
            endMax: "",

            departments: [],

            headers: [],
            formatReportData: [],

            reportDataTitles: {
              id: "ID",
              pickUpTime: "Trip Date",
              branch: "Branch",
              department: "Department",
              employee_name: "Name",
              IGAID: "IGA ID",
              employee_email: "Employee Email Id",
              reportingmanager: "Reporting Manager",
              reportingmanageremail: "Reporting Manager Email",
              tripType: "Trip Type",
              driver_name: "Driver Name",
              vendor_name: "Vendor Name",
              startlocation: "Employee Start Location",
              endlocation: "Employee Drop Location",
              oneWayDistance: "One Way Distance(KMS)",
              date: "Trip Created Time",
              assignTime: "Trip Assigned Time",
              tripAcceptedTime: "Trip Accepted Time by Driver",
              pickUpTime: "Scheduled Pickup time",
              start: "Actual Pickup time",
              end: "Actual Drop time",
              estimateTime: "ETA (Estimated Time of Arrival or Drop home)",
              travelledtime: "Actual Time of Arrival / Drop home",
              arrivalStatus: "Arrival Status(ON TIME/DELAYED)",
              totaltime: "Total travel Time(In MINS)",
              total: "Trip Amount"
            },
            options: {
              page: 1,
              itemsPerPage: 500,
              totalItems: 0,
              itemsPerPageOptions: [50, 100, 200, 500, 1000, 1500, 2000],
            },
            totalRows: 0,
            branchList:"",
            counter: 1,
        }),
        async mounted() {
          const branchListFromStorage = localStorage.getItem("branchList");
    try {
      this.branchList = branchListFromStorage ? JSON.parse(branchListFromStorage) : [];
    } catch (error) {
      console.error("Error parsing branchList from localStorage:", error);
      this.branchList = [];
    }
          this.getFilters();
          await this.fetchDepartments()
        },
        methods: {
            getFilters() {
                axios.get('settings/filter',)
                    .then((response) => {
                        this.filtersData = response.data
                    })
                    .catch((e) => {
                        this.print('catch' + e)
                    });
            },
            async fetchDepartments() {
              const response = await axios.get('settings/department/list')
              this.departments = response.data
          },
            getReports(event) {
              this.formData.reporttype = event
              this.formData.startdate = this.convertToMillisecond(this.startDate, 'YYYY MM DD')
              this.formData.enddate = this.convertToMillisecondEnd(this.endDate, 'YYYY MM DD')
                axios.post('report/periodical', this.formData)
                    .then((response) => {
                      if(response.data.length > 0) {
                        this.formatReportData = response.data
                        this.totalRows = response.data.length
                        this.prepareReportHeader();
                        // this.$VueExcel.methods.downloadExcelSheet(response.data.trips,this.startDateComputed+' - '+this.endDateComputed)
                        // this.reportDialogMessage = "Report generated";
                      } else {
                        this.$toasted.show('No trips with this filter combinations.', {
                          position: "bottom-center",
                          duration: 4000
                        });
                        // this.$root.$emit('show_snackbar', {text: "No trips with this filter combinations.", color: '#FF0000'})
                      }
                    })
                    .catch((e) => {
                        // this.reportDialog = false;
                        this.reportDialogMessage = "Something went wrong while generating report. Please try later.";
                        this.print('catch' + e)
                    });
            },

          prepareReportHeader() {
            this.headers = [];
            let titles = {};
            // this.headers = [{text: "#", value: 'check', sortable: false}];
            titles = this.reportDataTitles;
            for (let key in titles) {
              let headerObj = {text: titles[key], value: key, sortable: false,};
              this.headers.push(headerObj);
            }
          },

          formatReportData(rawData) {
            this.formattedReportData = [];
            let totalRow = {};
            let totalCount = 0;
            if (rawData.length > 0) {
              this.counter = 1;
              for (let value of rawData) {
                let dataRow = {};
                totalCount += value.count;
                dataRow = this.generateReportRow(value, this.counter);
                this.formattedReportData.push(dataRow);
                this.counter++;
              }
              totalRow = {
                branch: 'Total',
                count: totalCount,
              };
              this.formattedReportData.push(totalRow);
            } else {
              this.formattedReportData = [];
            }
          },
          generateReportRow(data) {
            let row = {
              branch: data.name,
              count: data.count,
            }
            return row;

          },

          exportReport() {
            let reportData = []
            this.reportDialog = true;
            this.reportDialogMessage = "Report will be ready in few seconds..."
            const sheetTitles = this.reportDataTitles;
            reportData = [...this.formatReportData]
            reportData.unshift(sheetTitles);
            VueExcel.methods.downloadExcelSheet(reportData, 'Periodical Report', {skipHeader: true});
            this.reportDialogMessage = "Report generated";
            this.reportDialog = false;
          },


            submitReport() {
                if(this.reportType === 1) {
                    // this.getTrips(2);
                    this.getReports(this.reportType);
                } else {
                    // this.getTrips(3);
                    this.getReports(this.reportType);
                }
            },



        },
        watch: {
          // startDate() {
          //     this.endDate = this.endMaxDateComputed;
          //     // this.endDate = this.convertDate(this.endMaxDateComputed, 'YYYY-MM-DD', 'MMM DD, YYYY');
          // },
          dateRange() {
            if (this.dateRange[0]) {
              if (this.dateRange[1]) {
                if (this.dateRange[0] > this.dateRange[1]) {
                  this.startDate = moment(this.dateRange[1]).format('YYYY-MM-DD')
                  this.endDate = moment(this.dateRange[0]).format('YYYY-MM-DD')
                } else {
                  this.startDate = moment(this.dateRange[0]).format('YYYY-MM-DD')
                  this.endDate = moment(this.dateRange[1]).format('YYYY-MM-DD')
                }
                this.menu1 = false
              }
            }
          }
        },
        computed: {
          dateComputed() {
            let start = ''
            let end = ''
            if (this.dateRange[0] > this.dateRange[1]) {
              start = moment(this.dateRange[1]).format('MMM DD')
              end = moment(this.dateRange[0]).format('MMM DD')
            } else {
              start = moment(this.dateRange[0]).format('MMM DD')
              end = moment(this.dateRange[1]).format('MMM DD')
            }
            return `${start} ~ ${end}`
          },

            // endMaxDateComputed() {
            //     //moment('YYYY-MM-DD HH:mm').valueOf()
            //     const startDate = this.convertDate(this.startDate, 'YYYY MM DD', 'YYYY-MM-DD');
            //     const futureMonth = moment(startDate).add(180, 'days').format("YYYY-MM-DD");
            //     const futureMonthMilli = this.convertToMillisecond(futureMonth, 'YYYY-MM-DD')
            //     const today = moment().format("YYYY-MM-DD");
            //     const todayMilli = this.convertToMillisecond(today, 'YYYY-MM-DD')
            //     if(futureMonthMilli <= todayMilli) {
            //         return futureMonth
            //     } else {
            //         return today
            //     }
            //     // return this.convertDate(this.endDate, 'YYYY MM DD', 'MMM DD, YYYY')
            // },
            dialogMessage() {
                return this.reportDialogMessage;
            }
        }
    }
</script>

<style scoped lang="scss">
    @import "../../assets/css/variables";

    .reports-outer {
      width: 100%;
      padding: 10px 20px;

    }
    .content-maininnerblk {
      height: calc(100vh - 150px);
      padding: 0;
      box-shadow: 0px 0px 5px 0px rgba(106, 7, 75, 0.15);
      background: white;
    }

    .bottom-block {
        display: flex;
        justify-content: center;
        padding-top: 10px;
        border: none !important;
        @media #{$screen-sm} {
            flex-direction: column;
        }

        .widget {
            height: 100%;
          display: flex;
          width: 100%;
          justify-content: center;
          .widget-body {
            display: flex;
            width: 100%;
            justify-content: center;
            .content-panel {
              display: flex;
              width: 100%;
              justify-content: center;
              .inner-forms-block {
                display: flex;
                width: 100%;
                justify-content: space-evenly;
                align-items: flex-end;
              }
            }
          }
        }
    }

    .report-content-column {
      padding: 10px;
      /*background: #f3f3f3;*/
      border: 1px solid rgba(#870947, 0.5);
      position: relative;

      .submit-block {
        position: absolute;
        bottom: 20px;
        left: 20px;
        width: 60%;
        border: 0 none;
        margin-top: 0;
        @media only screen and (min-width: 1200px) and (max-width: 1399px) {
          width: 67%;
        }
        @media only screen and (min-width: 992px) and (max-width: 1199px) {
          width: 67%;
        }

        ::v-deep .v-btn {
          color: white;
        }
      }

    }

    .v-data-table.v-data-table--fixed-header {
      /*background: #ececec;*/
      ::v-deep .v-data-table__wrapper {
        table {
          border-collapse: separate;

          tr {
            th {
              font-size: 0.9rem;
              font-weight: 700;
              color: #d9056d;
              font-family: 'Muli', sans-serif;
              white-space: nowrap;
              border: 1px solid rgba(0, 0, 0, 0.2);
              z-index: 1;
              /*background: #ececec;*/
              &:first-child {
                padding: 7px 12px;

                //position:sticky;
                //left:0;
                //z-index:2;
                //background: #ddd;
                //text-align: center;
                //width: 60px;
              }

              &:nth-child(1) {
                position: sticky;
                //left:61px;
                left: 0px;
                z-index: 2;
                background: #ddd;
                text-align: center;
              }

              &:last-child {
                //position: sticky;
                //right: 0;
                //z-index: 2;
                //background: #ff9600;
                //text-align: center;

                span {
                  width: 100%;
                  text-align: center;
                  display: inline-block;
                }
              }
            }

            td {
              font-size: 0.85rem;
              font-weight: 500;
              color: #440021;
              font-family: 'Muli', sans-serif;
              padding: 7px 5px;
              white-space: nowrap;
              border: 1px solid rgba(0, 0, 0, 0.2);

              &:first-child {
                padding: 7px 12px;

                //position:sticky;
                //left:0;
                //z-index:1;
                //background: #ddd;
                //text-align: center;
                //width: 60px;
              }

              &:nth-child(1) {
                position: sticky;
                //left:61px;
                left: 0px;
                z-index: 1;
                background: #ddd;
                text-align: center;
              }
              ::v-deep .v-btn {
                /*background: #737373 !important;*/
                /*color: #FFF !important;*/
                color: #d9056d;
                font-size: 0.75rem;
                font-weight: 700;
                text-transform: none;
              }

              div {
                white-space: pre;
              }

              &.lead-column {
                max-width: 150px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;

                div {
                  display: inline-block;
                  position: relative;
                  width: 100%;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
              }

              &.adjust-width {
                max-width: 150px;

                span {
                  //white-space: normal;

                  display: inline-block;
                  position: relative;
                  width: 100%;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
              }

              &.status-column {
                div {
                  white-space: pre;

                  span {
                    white-space: pre;
                    display: block;
                  }
                }
              }

              //&.fixed-column {
              //    position: absolute;
              //    width: 120px;
              //    background: #FFF;
              //    top: 0;
              //    height: 100%;
              //    &.column-1 {
              //        left: 0;
              //    }
              //    &.column-2 {
              //        left: 120px;
              //    }
              //    &.column-3 {
              //        left: 240px;
              //    }
              //}
            }

            &.pooled-trip {
              td {
                &:first-child {
                  span {
                    position: relative;
                    &:after {
                      content: "";
                      height: 1px;
                      width: 100%;
                      position: absolute;
                      left: 0;
                      bottom: 0;
                      border-bottom: 1px dotted blue;
                    }
                  }
                }
                &.pooled-trip-column {
                  text-decoration: line-through;
                  opacity: 0.6;
                }
              }
            }
            &.row-with-warning {
              td {
                background: #eecd7c;
                color: #000;
                //opacity: 0.4;
              }
            }

            &.row-with-errors {
              td {
                background: #e42e50;
                color: #FFF;
                //opacity: 0.4;
              }
            }
          }
        }
      }

      ::v-deep .v-data-footer {
        justify-content: flex-start;
      }
    }


    .single-row {
      ::v-deep .v-btn {
        color: white;
      }

    }

    .widget-title > h4 {
        font-size: 24px;
        color: #db0f82;
    }

    .button-outerblk {
        background: none;
        padding: 0;
    }


    .single-btn-field {
        width: 100%;
        height: auto;
        float: left;
        text-align: left;
        padding: 10px 20px;
    }

    .report-generate-message {
        .v-card__title {
            &.text-center {
                display: flex;
                justify-content: center;
            }
        }
        .report-loader {
            height: 100px;
            position: relative;
            .v-progress-circular.v-progress-circular--indeterminate.red--text {
                margin-left: -25px;
                transform: translateY(-50%);
            }
        }
    }

    .half-block {
      &:first-child {
        width: 100%;
        display: flex;
        justify-content: center;
      }
    }
    .widget {
      display: flex;
      flex-direction: column;
    }
    .widget-body {
      display: flex;
      width: 100%;
      .content-panel {
        display: flex;
        width: 100%;
        .inner-forms-block {
          display: flex;
          width: 100%;
        }
      }
    }

</style>