var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"trip-status-block",class:{ 'log-status': _vm.trip.tripLogs.length > 0 }},[_c('div',{staticClass:"status-container"},[_c('span',{staticClass:"status-block",style:(("color: " + (_vm.trip.tripStatus.color) + ";background-color:" + (_vm.hexToRgba(_vm.trip.tripStatus.color, 0.2)) + ";border: 1px solid " + (_vm.trip.tripStatus.color)))},[_vm._v(_vm._s(_vm.trip.tripStatus.name))]),_c('v-btn',{staticClass:"map-btn",attrs:{"x-small":"","color":"#0e64a8"},on:{"click":_vm.openMap}},[_vm._v("View Map")])],1),_c('div',{staticClass:"status-container"},_vm._l((_vm.trip.tripLogs),function(log,logIndex){return _c('div',{key:logIndex,staticClass:"time-info",class:{ 'delay-info': !log.onTime }},[(log.status === 'driver')?_c('v-tooltip',{attrs:{"bottom":"","color":"#494a4a"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('i',_vm._g(_vm._b({staticClass:"fas fa-taxi"},'i',attrs,false),on))]}}],null,true)},[_c('span',{staticStyle:{"font-size":"12px"}},[_vm._v("Driver Arrived")])]):_vm._e(),(log.status === 'user')?_c('v-tooltip',{attrs:{"bottom":"","color":"#3a3b3b"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('i',_vm._g(_vm._b({staticClass:"fas fa-user-circle"},'i',attrs,false),on))]}}],null,true)},[_c('span',{staticStyle:{"font-size":"12px"}},[_vm._v(_vm._s(log.name)+" - "+_vm._s(log.mobile))])]):_vm._e(),(log.status === 'end')?_c('v-tooltip',{attrs:{"bottom":"","color":"#3a3b3b"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('i',_vm._g(_vm._b({staticClass:"fas fa-building"},'i',attrs,false),on))]}}],null,true)},[_c('span',{staticStyle:{"font-size":"12px"}},[_vm._v("Trip Ended")])]):_vm._e(),_c('span',[_vm._v(_vm._s(log.time))])],1)}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }