<template>
    <v-card class="vehicle-assign-modal" elevation="0">
      <div class="vehicle-assign-header">
        <span>Vehicle Assign</span>
        <!-- Close Icon -->
        <span class="close-icon" @click="closeModal">×</span>
      </div>
  
      <div class="vehicle-block">
        <v-chip v-for="(vehicle, vehicleIndex) in driverDetails.vehicles" :key="vehicleIndex" outlined color="#4c99af">
          {{ vehicle.vehicleno }} 
          <i class="fas fa-times" @click="unlinkVehicle(vehicle)"></i>
        </v-chip>
      </div>
  
      <v-autocomplete v-model="vehicle" placeholder="Vehicle" label="Vehicle" outlined dense :items="vehicleList" return-object item-text="vehicleNo" hide-details></v-autocomplete>
  
      <div class="assign-button-blk">
        <v-btn color="#eea004" :disabled="!vehicle" @click="assignVehicle">Assign</v-btn>
      </div>
  
      <confirm ref="confirm"></confirm>
    </v-card>
  </template>
  
  <script>
  import axios from "@/axios/axios-global";
  import confirm from "@/components/common/Confirm";
  export default {
    name: "VehicleAssign",
    components: { confirm },
    props: {
      driverDetails: Object,
    },
    data() {
      return {
        vehicle: {},
        vehicleList: [],
      };
    },
    mounted() {
      this.getVehicleList();
    },
    methods: {
      async getVehicleList() {
        const response = await axios.post("vehicle/list", { is_pagination: false });
        this.vehicleList = response.data.list;
        if (this.vehicleList && this.vehicleList.length > 0) {
          this.vehicleList = this.vehicleList.filter((vehicle) => !vehicle.trash);
        }
      },
      async assignVehicle() {
        const payload = {
          vehicleNo: this.vehicle.vehicleNo,
          driver: {
            token: this.driverDetails.token,
            name: this.driverDetails.name,
          },
        };
        let confirmTitle = "Confirm Assign";
        let confirmText = `Please confirm to Assign ${this.vehicle.vehicleNo} to ${this.driverDetails.name}`;
        if (await this.$refs.confirm.open(confirmTitle, confirmText)) {
          this.loading = true;
          try {
            const response = await axios.patch("driver/vehicle/link", payload);
            if (response.status === 200) {
              this.driverDetails.vehicles.push({ vehicleno: this.vehicle.vehicleNo, id: this.vehicle.token });
              this.vehicle = "";
              this.loading = false;
              this.$toasted.show(response.data, {
                position: "bottom-center",
                duration: 4000,
              });
              this.$emit("update-driver");
            } else if (response.status === 206) {
              this.loading = false;
              this.$toasted.show(response.data, {
                position: "bottom-center",
                duration: 4000,
              });
            }
          } catch (error) {
            this.loading = false;
          }
        }
      },
      async unlinkVehicle(vehicle) {
        let confirmTitle = "Confirm UnAssign";
        let confirmText = `Please Confirm to Unassign ${vehicle.vehicleno} from ${this.driverDetails.name}`;
        let confirmIcon = "fas fa-exclamation";
        if (await this.$refs.confirm.open(confirmTitle, confirmText, { icon: confirmIcon, color: "red" })) {
          this.loading = true;
          try {
            const response = await axios.patch("driver/vehicle/unLink", {
              vehicleToken: vehicle.id,
              driverToken: this.driverDetails.token,
            });
            if (response.status === 200) {
              this.loading = false;
              this.$toasted.show(response.data, {
                position: "bottom-center",
                duration: 4000,
              });
              const index = this.driverDetails.vehicles.findIndex((item) => item.id === vehicle.id);
              this.driverDetails.vehicles.splice(index, 1);
            } else {
              this.$toasted.show(response.data, {
                position: "bottom-center",
                duration: 4000,
              });
              this.loading = false;
            }
          } catch (error) {
            console.log("err", error);
            this.loading = false;
          }
        }
      },
      closeModal() {
        this.$emit("close"); // Emits an event to close the modal
      },
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .vehicle-assign-modal {
    display: flex;
    flex-direction: column;
    padding: 20px;
    border-radius: 0;
    margin: 0 10px;
    height: fit-content;
  
    .vehicle-assign-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #fa7815;
      font-weight: 600;
      font-size: 1.3rem;
      padding: 3px;
      width: 100%;
      border-bottom: 1px solid #cacaca;
      margin-bottom: 10px;
    }
  
    .close-icon {
      font-size: 1.5rem;
      cursor: pointer;
      color: #fa7815;
    }
  
    .vehicle-block {
      display: flex;
      align-items: center;
      gap: 5px;
      margin-bottom: 10px;
  
      .fas {
        margin-left: 5px;
        color: rgb(120, 33, 33);
        cursor: pointer;
      }
    }
  
    .assign-button-blk {
      display: flex;
      flex-direction: row-reverse;
      margin-top: 10px;
    }
  }
  </style>
  