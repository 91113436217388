<template>
 <div class="vehicle-list">
  <v-data-table :headers="headers" :items="getRemarks" :items-per-page="5" hide-default-footer>
   <template v-slot:item.status="{ item }">
    <v-chip :color="!item.action ? 'green' : 'red'" small dark>{{ !item.action ? "Activated" : "Deactivated" }}</v-chip>
   </template>
  </v-data-table>
 </div>
</template>

<script>
import moment from "moment";
export default {
 props: {
  token: String,
 },
 data: () => {
  return {
   headers: [
    { text: "Employee", sortable: true, value: "remarksBy" },
    { text: "Reason", value: "reason" },
    { text: "Date", value: "date" },
    { text: "Status", value: "status", sortable: true },
   ],
   Remarks: [],

   vehicleDetails: {},
  };
 },
 mounted() {
  this.getVehicleDetails();
 },
 methods: {
  async getVehicleDetails() {
   const payload = {
    driverId: this.token,
   };
   const response = await axios.post("vehicle/details", payload);
   console.log(response);
   this.vehicleDetails = response.data;
  },
 },
 computed: {
  getRemarks() {
   if (this.vehicleDetails && this.vehicleDetails.reason && this.vehicleDetails.reason.length > 0) {
    this.vehicleDetails.reason.map((remark) => {
     remark.date = remark.date ? moment(remark.date).format("YYYY-MM-DD HH:mm:ss") : "Not found";
     return remark;
    });
    return this.vehicleDetails.reason;
   } else {
    return [];
   }
  },
 },
};
</script>

<style scoped lang="scss">
.vehicle-list {
 padding: 10px;
}
</style>
