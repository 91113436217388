<template>
  <div class="admin-insert-container">
    <div class="admin-content-block">
      <h3>Admin {{ status }}</h3> 
      <div class="admin-field-block">
        <div class="filter-column">
          <span class="header">Name </span>
          <div class="filter-row-block">
            <v-text-field v-model="admin.name" dense outlined :error-messages="nameError" @blur="$v.admin.name.$touch()"
              @input="$v.admin.name.$touch()">
            </v-text-field>
          </div>

        </div>
        <div class="filter-column">
          <span class="header">Mobile Number</span>
          <div class="filter-row-block">
            <v-text-field v-model="admin.mobile" dense outlined :error-messages="mobileError"
              @blur="$v.admin.mobile.$touch()" @input="$v.admin.mobile.$touch()">
            </v-text-field>
          </div>

        </div>
        <div class="filter-column">
          <span class="header">Email</span>
          <div class="filter-row-block">
            <v-text-field v-model="admin.email" dense outlined :error-messages="emailError"
              @blur="$v.admin.email.$touch()" @input="$v.admin.email.$touch()">
            </v-text-field>
          </div>

        </div>
        <div v-if="branchList && branchList.length > 0" class="filter-column">
          <span class="header">Branch</span>
          <div class="filter-row-block">
            <v-select :items="branchList" label="Branch" item-text="name" dense :error-messages="branchError"
              @blur="$v.admin.branch.$touch()" @input="$v.admin.branch.$touch()" return-object outlined multiple
              v-model="admin.branch" :menu-props="{ offsetY: true, zIndex: 1000, maxHeight: '300px' }"></v-select>

          </div>
        </div>

        <div class="add-button-row">
          <v-btn color="black" class="submit-btn" @click="submitInsert">Submit</v-btn>
          <v-btn color="#cfcfcf" @click="clearData">Clear</v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { alpha, alphaNum, email, maxLength, minLength, numeric, required } from "vuelidate/lib/validators";
import { helpers } from 'vuelidate/lib/validators'

export default {
  name: "adminInsert",
  props: {
    admin: [Object, String],
    status: String,
  },
  mixins: [validationMixin],

  validations() {
    return {
      admin: {
        name: { required, alphaWithSpaces: helpers.regex('alphaWithSpaces', /^[a-zA-Z\s]+$/) },
        mobile: { required, minLength: minLength(10), maxLength: maxLength(10), numeric },
        email: { required, email },
        branch:{}
      }
    }
  },
  data() {
    return {
      branchList: "",
      selectedBranches: []
    }
  },
  mounted() {
    const branchListFromStorage = localStorage.getItem("branchList");
  try {
    this.branchList = branchListFromStorage ? JSON.parse(branchListFromStorage) : [];

    if (this.admin.branch && Array.isArray(this.admin.branch)) {
      this.admin.branch = this.admin.branch.map((assignedBranch) => {
        return this.branchList.find((branch) => branch.id === assignedBranch.id) || assignedBranch;
      });
    }
  } catch (error) {
    console.error("Error parsing branchList from localStorage:", error);
    this.branchList = [];
  }
  },
  methods: {
    submitInsert() {
      this.$v.$touch()
      if (this.$v.$anyError) {
      } else {
        this.$v.$reset()
        if (this.admin.branch) {
          const selectedBranches = this.admin.branch.map(branch => {
            const branchData = this.branchList.find(b => b.id === branch.id);
            return branchData ? { id: branchData.id, name: branchData.name } : null;
          }).filter(branch => branch !== null);
        }

        this.$emit('on-submit', { admin: this.admin, status: this.status, });
      }

    },
    clearData() {
      this.$v.$reset()
      this.$emit('on-clear')
    },
  },
  computed: {
    nameError() {
      const errors = []
      if (!this.$v.admin.name.$dirty) return errors
      !this.$v.admin.name.required && errors.push('Name is required.')
      !this.$v.admin.name.alphaWithSpaces && errors.push('Name must start with a letter and contain only letters and spaces.')
      return errors
    },
    mobileError() {
      const errors = []
      if (!this.$v.admin.mobile.$dirty) return errors
      !this.$v.admin.mobile.required && errors.push('Number is required.')
      !this.$v.admin.mobile.minLength && errors.push('Enter a valid Number')
      !this.$v.admin.mobile.maxLength && errors.push('Enter a valid Number')
      !this.$v.admin.mobile.numeric && errors.push('Enter a valid Number')
      return errors
    },
    emailError() {
      const errors = []
      if (!this.$v.admin.email.$dirty) return errors
      !this.$v.admin.email.required && errors.push('Email is required.')
      !this.$v.admin.email.email && errors.push('Enter a valid email.')
      return errors
    },
    // branchError() {
    //   const errors = [];
    //   if (!this.$v.admin.branch.$dirty) return errors;
    //   !this.$v.admin.branch.required && errors.push('Branch is required.');
    //   return errors;
    // }
  }
}
</script>

<style scoped lang="scss">
.admin-insert-container {
  padding: 10px;
  width: 40%;

  .admin-content-block {
    display: flex;
    flex-direction: column;

    .admin-field-block {
      display: flex;
      margin: 10px;
      column-gap: 10px;
      row-gap: 10px;
      flex-wrap: wrap;

      .filter-column {
        display: flex;
        //align-items: flex-end;
        justify-content: space-between;
        width: 100%;

        .filter-row-block {
          display: flex;
          flex-direction: column;
          width: 70%;

          ::v-deep.v-text-field__details {
            margin-bottom: 0;
          }

          .v-menu__content {
            z-index: 1000 !important;
            margin-top: 10px;
          }


          .error-field {
            color: red;
            padding: 3px 5px;
          }

          .error-content {
            visibility: hidden;
          }
        }


        .header {
          width: 30% !important;
          float: left;
          color: #8d8d8d;
          font-weight: bold;
          position: relative;
          margin-right: 15px;
          margin-top: 10px;
          font-size: 14px !important;

          &:after {
            position: absolute;
            right: 0;
            content: ":";
            font-weight: bold;
          }
        }
      }

      .add-button-row {
        display: flex;
        justify-content: center;
        width: 100%;
        column-gap: 10px;

        ::v-deep .submit-btn {
          color: white;
        }

        ::v-deep .v-btn {
          font-weight: bold;
        }
      }
    }

    h3 {
      text-align: center;
      padding: 10px;
      font-size: 1.7em;
      font-weight: bold;
      text-transform: uppercase;
    }
  }

}
</style>