<template>
 <div class="document-insert-content-modal">
  <span class="header-modal">Upload Documents</span>
  <div class="document-upload-modal" v-if="vehicleDetails">
   <v-card class="photo-insert-modal" elevation="2">
    <input accept="image/*,application/pdf" ref="rcUpload" @change="insertRC" type="file" class="input-blk" />
    <div class="image-upload-modal" @click="$refs.rcUpload.click()" v-if="rcImage === ''">
     <i class="fas fa-images"></i>
     <span>Upload Image</span>
    </div>
    <div class="image-upload-modal" v-if="rcImage !== ''">
     <v-btn v-if="isPdf(rcImage)" @click="previewImage(rcImage)" small class="p-absolute">View PDF</v-btn>
     <img :src="rcImage" class="image-view-blk" v-if="!isPdf(rcImage)" @click="previewImage(rcImage)" />
     <object v-else :data="rcImage" width="100%" height="500px"></object>
    </div>
    <div class="image-name-blk">
     <span>RC Book Image</span>
     <div class="buttons" v-if="rcImage && vehicleDocument.rcbook">
      <v-chip readonly outlined :color="getExpiredStatus(vehicleDocument.rcbook.verified).color">{{ getExpiredStatus(vehicleDocument.rcbook.verified).text }}</v-chip>
      <v-btn small color="#83b9e5" @click="$refs.rcUpload.click()" v-if="rcImage !== ''">Edit<i class="fas fa-pen"></i></v-btn>
     </div>
    </div>
   </v-card>
   <v-card class="photo-insert-modal" elevation="2">
    <input accept="image/*,application/pdf" ref="interiorProof" @change="insertInterior" type="file" class="input-blk" />
    <div class="image-upload-modal" @click="$refs.interiorProof.click()" v-if="interiorImage === ''">
     <i class="fas fa-images"></i>
     <span>Upload Image</span>
    </div>
    <div class="image-upload-modal" v-if="interiorImage !== ''">
     <v-btn v-if="isPdf(interiorImage)" @click="previewImage(interiorImage)" small class="p-absolute">View PDF</v-btn>
     <img :src="interiorImage" class="image-view-blk" v-if="!isPdf(interiorImage)" @click="previewImage(interiorImage)" />
     <object v-else :data="interiorImage" width="100%" height="500px"></object>
    </div>
    <div class="image-name-blk">
     <span>Interior Image</span>
     <div class="buttons" v-if="interiorImage && vehicleDocument.interior">
      <v-chip readonly outlined :color="getExpiredStatus(vehicleDocument.interior.verified).color">{{ getExpiredStatus(vehicleDocument.interior.verified).text }}</v-chip>
      <v-btn small color="#83b9e5" @click="$refs.interiorProof.click()" v-if="interiorImage !== ''">Edit<i class="fas fa-pen"></i></v-btn>
     </div>
    </div>
   </v-card>
   <v-card class="photo-insert-modal" elevation="2">
    <input accept="image/*,application/pdf" ref="exteriorProof" @change="insertExterior" type="file" class="input-blk" />
    <div class="image-upload-modal" @click="$refs.exteriorProof.click()" v-if="exteriorImage === ''">
     <i class="fas fa-images"></i>
     <span>Upload Image</span>
    </div>
    <div class="image-upload-modal" v-if="exteriorImage !== ''">
     <v-btn v-if="isPdf(exteriorImage)" @click="previewImage(exteriorImage)" small class="p-absolute">View PDF</v-btn>
     <img :src="exteriorImage" class="image-view-blk" v-if="!isPdf(exteriorImage)" @click="previewImage(exteriorImage)" />
     <object v-else :data="exteriorImage" width="100%" height="500px"></object>
    </div>
    <div class="image-name-blk">
     <span>Exterior Image</span>
     <div class="buttons" v-if="exteriorImage && vehicleDocument.exterior">
      <v-chip readonly outlined :color="getExpiredStatus(vehicleDocument.exterior.verified).color">{{ getExpiredStatus(vehicleDocument.exterior.verified).text }}</v-chip>
      <v-btn small color="#83b9e5" @click="$refs.exteriorProof.click()" v-if="exteriorImage !== ''">Edit<i class="fas fa-pen"></i></v-btn>
     </div>
    </div>
   </v-card>
   <v-card class="photo-insert-modal" elevation="2">
    <input accept="image/*,application/pdf" ref="insuranceProof" @change="insertInsurance" type="file" class="input-blk" />
    <div class="image-upload-modal" @click="$refs.insuranceProof.click()" v-if="insuranceImage === ''">
     <i class="fas fa-images"></i>
     <span>Upload Image</span>
    </div>
    <div class="image-upload-modal" v-if="insuranceImage !== ''">
     <v-btn v-if="isPdf(insuranceImage)" @click="previewImage(insuranceImage)" small class="p-absolute">View PDF</v-btn>
     <img :src="insuranceImage" class="image-view-blk" v-if="!isPdf(insuranceImage)" @click="previewImage(insuranceImage)" />
     <object v-else :data="insuranceImage" width="100%" height="500px"></object>
    </div>
    <div class="image-name-blk">
     <span>Insurance</span>
     <div class="buttons" v-if="insuranceImage && vehicleDocument.insurance">
      <v-chip readonly outlined :color="getExpiredStatus(vehicleDocument.insurance.verified).color">{{ getExpiredStatus(vehicleDocument.insurance.verified).text }}</v-chip>
      <v-btn small color="#83b9e5" @click="$refs.insuranceProof.click()" v-if="insuranceImage !== ''">Edit<i class="fas fa-pen"></i></v-btn>
     </div>
    </div>
   </v-card>

   <v-card class="photo-insert-modal" elevation="2">
    <input accept="image/*,application/pdf" ref="carriagePermitProof" @change="insertCarriagePermit" type="file" class="input-blk" />
    <div class="image-upload-modal" @click="$refs.carriagePermitProof.click()" v-if="carriagePermit === ''">
     <i class="fas fa-images"></i>
     <span>Upload Image</span>
    </div>
    <div class="image-upload-modal" v-if="carriagePermit !== ''">
     <v-btn v-if="isPdf(carriagePermit)" @click="previewImage(carriagePermit)" small class="p-absolute">View PDF</v-btn>
     <img :src="carriagePermit" class="image-view-blk" v-if="!isPdf(carriagePermit)" @click="previewImage(carriagePermit)" />
     <object v-else :data="carriagePermit" width="100%" height="500px"></object>
    </div>
    <div class="image-name-blk">
     <span>Carriage Permit</span>
     <div class="buttons" v-if="carriagePermit && vehicleDocument.carriagepermit">
      <v-chip readonly outlined :color="getExpiredStatus(vehicleDocument.insurance.verified).color">{{ getExpiredStatus(vehicleDocument.carriagepermit.verified).text }}</v-chip>
      <v-btn small color="#83b9e5" @click="$refs.carriagePermitProof.click()" v-if="carriagePermit !== ''">Edit<i class="fas fa-pen"></i></v-btn>
     </div>
    </div>
   </v-card>
   <v-card class="photo-insert-modal" elevation="2">
    <input accept="image/*,application/pdf" ref="taxValidityProof" @change="insertTaxValidity" type="file" class="input-blk" />
    <div class="image-upload-modal" @click="$refs.taxValidityProof.click()" v-if="taxValidity === ''">
     <i class="fas fa-images"></i>
     <span>Upload Image</span>
    </div>
    <div class="image-upload-modal" v-if="taxValidity !== ''">
     <v-btn v-if="isPdf(taxValidity)" @click="previewImage(taxValidity)" small class="p-absolute">View PDF</v-btn>
     <img :src="taxValidity" class="image-view-blk" v-if="!isPdf(taxValidity)" @click="previewImage(taxValidity)" />
     <object v-else :data="taxValidity" width="100%" height="500px"></object>
    </div>
    <div class="image-name-blk">
     <span>Tax</span>
     <div class="buttons" v-if="taxValidity && vehicleDocument.tax && vehicleDocument.tax">
      <v-chip readonly outlined :color="getExpiredStatus(vehicleDocument.tax.verified).color">{{ getExpiredStatus(vehicleDocument.tax.verified).text }}</v-chip>
      <v-btn small color="#83b9e5" @click="$refs.taxValidityProof.click()" v-if="taxValidity !== ''">Edit<i class="fas fa-pen"></i></v-btn>
     </div>
    </div>
   </v-card>
   <v-card class="photo-insert-modal" elevation="2">
    <input accept="image/*,application/pdf" ref="fitnessProof" @change="insertFitness" type="file" class="input-blk" />
    <div class="image-upload-modal" @click="$refs.fitnessProof.click()" v-if="fitnessImage === ''">
     <i class="fas fa-images"></i>
     <span>Upload Image</span>
    </div>
    <div class="image-upload-modal" v-if="fitnessImage !== ''">
     <v-btn v-if="isPdf(fitnessImage)" @click="previewImage(fitnessImage)" small class="p-absolute">View PDF</v-btn>
     <img :src="fitnessImage" class="image-view-blk" v-if="!isPdf(fitnessImage)" @click="previewImage(fitnessImage)" />
     <object v-else :data="fitnessImage" width="100%" height="500px"></object>
    </div>
    <div class="image-name-blk">
     <span>Fitness</span>
     <div class="buttons" v-if="fitnessImage && vehicleDocument.fitness">
      <v-chip readonly outlined :color="getExpiredStatus(vehicleDocument.fitness.verified).color">{{ getExpiredStatus(vehicleDocument.fitness.verified).text }}</v-chip>
      <v-btn small color="#83b9e5" @click="$refs.fitnessProof.click()" v-if="fitnessImage !== ''">Edit<i class="fas fa-pen"></i></v-btn>
     </div>
    </div>
   </v-card>
   <v-card class="photo-insert-modal" elevation="2">
    <!-- {{ PUC }} -->
    <input accept="image/*,application/pdf" ref="PUCProof" @change="insertPUC" type="file" class="input-blk" />
    <div class="image-upload-modal" @click="$refs.PUCProof.click()" v-if="PUC === ''">
     <i class="fas fa-images"></i>
     <span>Upload Image</span>
    </div>
    <div class="image-upload-modal" v-if="PUC !== ''">
     <v-btn v-if="isPdf(PUC)" @click="previewImage(PUC)" small class="p-absolute">View PDF</v-btn>
     <img :src="PUC" class="image-view-blk" v-if="!isPdf(PUC)" @click="previewImage(PUC)" />
     <object v-else :data="PUC" width="100%" height="500px"></object>
    </div>
    <div class="image-name-blk">
     <span>PUC</span>
     <div class="buttons" v-if="PUC && vehicleDocument.pollution">
      <v-chip readonly outlined :color="getExpiredStatus(vehicleDocument.pollution.verified).color">{{ getExpiredStatus(vehicleDocument.pollution.verified).text }}</v-chip>
      <v-btn small color="#83b9e5" @click="$refs.PUCProof.click()" v-if="PUC !== ''">Edit<i class="fas fa-pen"></i></v-btn>
     </div>
    </div>
   </v-card>
  </div>
  <div v-else class="no-vehicle-document">
   <span>No Data Available</span>
  </div>

  <div class="button-row">
   <v-btn color="#ed5e5e" @click="cancelSubmit">Close</v-btn>
  </div>
  <!-- Image Preview Dialog -->

  <vue-easy-lightbox :visible="imagePreviewDialog" :imgs="[imageToPreview]" @hide="imagePreviewDialog = false" v-if="!isPdf(imageToPreview)"></vue-easy-lightbox>

  <v-dialog v-model="imagePreviewDialog" class="preview-board" width="700px" max-height="100%" v-if="isPdf(imageToPreview)">
   <iframe width="100%" :src="imageToPreview" style="height: 100vh; overflow: auto"></iframe>
  </v-dialog>
 </div>
</template>

<script>
import axios from "@/axios/axios-global";
import { encryptUtil } from "../../../Utils/decrypt";
export default {
 name: "DocumentInsert",
 props: {
  vehicleDetails: Object,
  resetTrigger: String,
 },
 data: () => {
  return {
   rcImage: "",
   interiorImage: "",
   exteriorImage: "",
   insuranceImage: "",
   carriagePermit: "",
   taxValidity: "",
   fitnessImage: "",
   PUC: "",
   baseUrl: "https://taski2020.s3.ap-south-1.amazonaws.com/",
   vehicleDocument: "",
   imageToPreview: "",
   imagePreviewDialog: false,
   InsExpiryDate: null,
   TaxExpiryDate: null,
   RCExpiryDate: null,
   FtsExpiryDate: null,
   PUCExpiryDate: null,
   selectedFile: "",
   imagePreviewDialog: false,

   documentStatus: [
    { id: 0, text: "Rejected", color: "#EB6746" },
    { id: 1, text: "Verified", color: "#0DA300" },
    { id: 2, text: "Pending", color: "#FF9800" },
    { id: 3, text: "Expiring soon", color: "red" },
    { id: 4, text: "Expired", color: "#EB6746" },
   ],
  };
 },
 mounted() {
  this.getVehicleDetails({ load_data: true });
 },
 watch: {
  resetTrigger() {
   this.resetDetails({ load_data: this.resetTrigger == "cab-document-tab" ? true : false });
  },
 },
 methods: {
  isPdf(url) {
   return url && url.toLowerCase().includes(".pdf");
  },
  getExpiredStatus(status) {
   return this.documentStatus.find((item) => item.id === status);
  },
  resetDetails(data) {
   let default_val = {
    color: "",
    text: "",
    verified: null,
    image: "",
   };
   this.vehicleDocument = {
    rcbook: { default_val },
    exterior: { default_val },
    interior: { default_val },
    insurance: { default_val },
    carriagepermit: { default_val },
    pollution: { default_val },
    tax: { default_val },
    fitness: { default_val },
   };
   this.InsExpiryDate = null;
   this.TaxExpiryDate = null;
   this.RCExpiryDate = null;
   this.FtsExpiryDate = null;
   this.PUCExpiryDate = null;
   this.selectedFile = "";
   this.CPExpiryDate = null;
   this.IDNumber = null;
   if (data.load_data) {
    this.getVehicleDetails();
   }
  },
  assignDetails() {
   this.rcImage = this.vehicleDocument.rcbook ? this.vehicleDocument.rcbook.image : "";
   this.interiorImage = this.vehicleDocument.interior ? this.vehicleDocument.interior.image : "";
   this.exteriorImage = this.vehicleDocument.exterior ? this.vehicleDocument.exterior.image : "";
   this.PUC = this.vehicleDocument.pollution ? this.vehicleDocument.pollution.image : "";
   this.PUCExpiryDate = this.vehicleDocument.pollution && this.vehicleDocument.pollution.expdate ? this.vehicleDocument.pollution.expdate : null;
   this.insuranceImage = this.vehicleDocument.insurance ? this.vehicleDocument.insurance.image : "";
   this.InsExpiryDate = this.vehicleDocument.insurance && this.vehicleDocument.insurance.expdate ? this.vehicleDocument.insurance.expdate : null;
   this.carriagePermit = this.vehicleDocument.carriagepermit ? this.vehicleDocument.carriagepermit.image : "";
   this.CPExpiryDate = this.vehicleDocument.carriagepermit && this.vehicleDocument.carriagepermit.expdate ? this.vehicleDocument.carriagepermit.expdate : null;
   this.taxValidity = this.vehicleDocument.tax ? this.vehicleDocument.tax.image : "";
   this.TaxExpiryDate = this.vehicleDocument.tax && this.vehicleDocument.tax.expdate ? this.vehicleDocument.tax.expdate : null;
   this.fitnessImage = this.vehicleDocument.fitness ? this.vehicleDocument.fitness.image : "";
   this.FtsExpiryDate = this.vehicleDocument.fitness && this.vehicleDocument.fitness.expdate ? this.vehicleDocument.fitness.expdate : null;
  },
  async getVehicleDetails() {
   if (this.vehicleDetails.token) {
    this.loading = true;
    const response = await axios.post("vehicle/details", { vehicleId: this.vehicleDetails.token });
    this.vehicleDocument = response.data.document;
    this.assignDetails();
    this.loading = false;
   }
  },
  async insertRC(event) {
    console.log(event.target.files);
   let files = event.target.files[0];
   if (event.target.files.length > 0) {
    let formData = new FormData();
    const payload = {
     vehicleId: this.vehicleDetails.token,
     imageName: "rcBook",
     expdate: this.RCExpiryDate,
    };
    const setencryptUtil =  new encryptUtil({ decrypt: payload });
    const data=setencryptUtil.getEncryptedData()
    formData.append("data", data);
    formData.append("image", files);
    const response = await axios.patch("vehicle/document/update", formData);
    if (response.status === 200) {
     setTimeout(async () => {
      await this.getVehicleDetails();
     }, 500);
     this.$toasted.show(response.data.message, {
      position: "bottom-center",
      duration: 4000,
     });
    } else {
     this.$toasted.show(response.data, {
      position: "bottom-center",
      duration: 4000,
     });
    }
   }
  },
  async insertInterior(event) {
   let files = event.target.files[0];
   // this.idImage = URL.createObjectURL(files)
   if (event.target.files.length > 0) {
    let formData = new FormData();
    const payload = {
     vehicleId: this.vehicleDetails.token,
     imageName: "interior",
    };
    const setencryptUtil =  new encryptUtil({ decrypt: payload });
    const data=setencryptUtil.getEncryptedData()
    formData.append("data", data);
    formData.append("image", files);
    const response = await axios.patch("vehicle/document/update", formData);
    if (response.status === 200) {
     setTimeout(async () => {
      await this.getVehicleDetails();
     }, 500);
     this.$toasted.show(response.data.message, {
      position: "bottom-center",
      duration: 4000,
     });
    } else {
     this.$toasted.show(response.data, {
      position: "bottom-center",
      duration: 4000,
     });
    }
   }
  },

  previewImage(imageSrc) {
   this.imageToPreview = imageSrc;
   this.imagePreviewDialog = true;
  },
  async insertExterior(event) {
   let files = event.target.files[0];
   // this.licenceBackImage = URL.createObjectURL(files)
   if (event.target.files.length > 0) {
    let formData = new FormData();
    const payload = {
     vehicleId: this.vehicleDetails.token,
     imageName: "exterior",
    };
    const setencryptUtil =  new encryptUtil({ decrypt: payload });
    const data=setencryptUtil.getEncryptedData()
    formData.append("data", data);
    formData.append("image", files);
    const response = await axios.patch("vehicle/document/update", formData);
    if (response.status === 200) {
     setTimeout(async () => {
      await this.getVehicleDetails();
     }, 500);
     this.$toasted.show(response.data.message, {
      position: "bottom-center",
      duration: 4000,
     });
    } else {
     this.$toasted.show(response.data, {
      position: "bottom-center",
      duration: 4000,
     });
    }
   }
  },
  async insertInsurance(event) {
   let files = event.target.files[0];
   // this.licenceFrontImage = URL.createObjectURL(files)
   if (event.target.files.length > 0) {
    let formData = new FormData();
    const payload = {
     vehicleId: this.vehicleDetails.token,
     imageName: "insurance",
     expdate: this.InsExpiryDate,
    };
    const setencryptUtil =  new encryptUtil({ decrypt: payload });
    const data=setencryptUtil.getEncryptedData()
    formData.append("data", data);
    formData.append("image", files);
    const response = await axios.patch("vehicle/document/update", formData);
    if (response.status === 200) {
     setTimeout(async () => {
      await this.getVehicleDetails();
     }, 500);
     this.$toasted.show(response.data.message, {
      position: "bottom-center",
      duration: 4000,
     });
    } else {
     this.$toasted.show(response.data, {
      position: "bottom-center",
      duration: 4000,
     });
    }
   }
  },
  async insertCarriagePermit(event) {
   let files = event.target.files[0];
   // this.policeClearanceImage = URL.createObjectURL(files)
   if (event.target.files.length > 0) {
    let formData = new FormData();
    const payload = {
     vehicleId: this.vehicleDetails.token,
     imageName: "carriagepermit",
     expdate: this.CPExpiryDate,
    };
    const setencryptUtil =  new encryptUtil({ decrypt: payload });
    const data=setencryptUtil.getEncryptedData()
    formData.append("data", data);
    formData.append("image", files);
    const response = await axios.patch("vehicle/document/update", formData);
    if (response.status === 200) {
     setTimeout(async () => {
      await this.getVehicleDetails();
     }, 500);
     this.$toasted.show(response.data.message, {
      position: "bottom-center",
      duration: 4000,
     });
    } else {
     this.$toasted.show(response.data, {
      position: "bottom-center",
      duration: 4000,
     });
    }
   }
  },
  async insertTaxValidity(event) {
   let files = event.target.files[0];
   // this.policeClearanceImage = URL.createObjectURL(files)
   if (event.target.files.length > 0) {
    let formData = new FormData();
    const payload = {
     vehicleId: this.vehicleDetails.token,
     imageName: "tax",
     expdate: this.TaxExpiryDate,
    };
    const setencryptUtil =  new encryptUtil({ decrypt: payload });
    const data=setencryptUtil.getEncryptedData()
    formData.append("data", data);
    formData.append("image", files);
    const response = await axios.patch("vehicle/document/update", formData);
    if (response.status === 200) {
     setTimeout(async () => {
      await this.getVehicleDetails();
     }, 500);
     this.$toasted.show(response.data.message, {
      position: "bottom-center",
      duration: 4000,
     });
    } else {
     this.$toasted.show(response.data, {
      position: "bottom-center",
      duration: 4000,
     });
    }
   }
  },
  async insertFitness(event) {
   let files = event.target.files[0];
   // this.policeClearanceImage = URL.createObjectURL(files)
   if (event.target.files.length > 0) {
    let formData = new FormData();
    const payload = {
     vehicleId: this.vehicleDetails.token,
     imageName: "fitness",
     expdate: this.FtsExpiryDate,
    };
    const setencryptUtil =  new encryptUtil({ decrypt: payload });
    const data=setencryptUtil.getEncryptedData()
    formData.append("data", data);
    formData.append("image", files);
    const response = await axios.patch("vehicle/document/update", formData);
    if (response.status === 200) {
     setTimeout(async () => {
      await this.getVehicleDetails();
     }, 500);
     this.$toasted.show(response.data.message, {
      position: "bottom-center",
      duration: 4000,
     });
    } else {
     this.$toasted.show(response.data, {
      position: "bottom-center",
      duration: 4000,
     });
    }
   }
  },
  async insertPUC(event) {
   let files = event.target.files[0];
   // this.policeClearanceImage = URL.createObjectURL(files)
   if (event.target.files.length > 0) {
    let formData = new FormData();
    const payload = {
     vehicleId: this.vehicleDetails.token,
     imageName: "pollution",
     expdate: this.PUCExpiryDate,
    };
    const setencryptUtil =  new encryptUtil({ decrypt: payload });
    const data=setencryptUtil.getEncryptedData()
    formData.append("data", data);
    formData.append("image", files);
    const response = await axios.patch("vehicle/document/update", formData);
    if (response.status === 200) {
     setTimeout(async () => {
      await this.getVehicleDetails();
     }, 500);
     this.$toasted.show(response.data.message, {
      position: "bottom-center",
      duration: 4000,
     });
    } else {
     this.$toasted.show(response.data, {
      position: "bottom-center",
      duration: 4000,
     });
    }
   }
  },
    cancelSubmit() {
   this.$emit("cancel-insert");
  },
 },
};
</script>

<style scoped lang="scss">
@import "src/components/vehicle-management/vehicle-document/vehicleDocument";
.invoice-content-modal {
 text-align: center;
 width: 100%;
 z-index: 100;
 .image-preview {
  width: 100%;
  object-fit: contain;
  padding: 10px;
 }
}

.button-row {
 position: sticky;
 display: flex;
 justify-content: flex-end;
 bottom: 0;
 background-color: white;
 padding-bottom: 20px;
}

.header-modal {
 color: #fa7815;
 font-weight: bold;
 font-size: 17px;
 padding: 5px;
 width: 100%;
 border-bottom: 1px solid #cacaca;
 text-transform: uppercase;
}
.preview-board {
 background-size: cover;
 .preview-cover {
  max-width: 700px;
 }
}
.headline {
 display: flex;
 justify-content: flex-end;
 .v-btn {
  background-color: #f44336;
  color: white;
 }
}
</style>
