<template>
    <div class="search-box">
     <div class="trip-list-block">
      <div class="trip-list" v-for="(trip, index) in searchList" :key="index" @click="showTripDetails(trip)">
       <div class="trip-content">
        <h3>{{trip.user}}</h3>
        <div class="trip-content-details">
         <span>{{ trip.smallid }}</span>
        </div>
       </div>
      </div>
     </div>
    </div>
   </template>
   
   <script>
   export default {
    name: "DriverSearchList",
    props: {
     searchList: Array,
    },
    methods: {
     showTripDetails(trip) {
      this.$emit("get-trip", trip);
     },
    },
   };
   </script>
   
   <style lang="scss" scoped>
   .search-box {
    //  background: white;
    box-shadow: -3px 5px 5px #717171;
    width: 100%;
    .trip-list-block {
     max-height: 300px;
     overflow-y: auto;
     position: absolute;
     background: rgb(255, 255, 255);
     width: 278px;
     left: 0px;
    //  top: 100px;
     box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
     cursor: pointer;
     .trip-list {
      display: flex;
      padding: 7px 10px;
      align-items: center;
      border-bottom: 1px solid rgb(231, 231, 231);
      font-size: 13px;
      gap: 10px;
      color: #333333;
   
      //    color: black;
   
      .icon-view {
       padding: 0 5px;
      }
      .fas {
       font-size: 16px;
      }
      .trip-content {
       display: flex;
       flex-direction: column;
       align-items: flex-start;
       width: 100%;
       h3 {
        font-size: 13px;
        color: black;
        font-weight: 400;
       }
   
       .trip-content-details {
        display: flex;
        justify-content: space-between;
        font-size: 12px;
        width: 100%;
        span {
         color: #fc790d;
         font-weight: 700;
         overflow: hidden;
         text-overflow: ellipsis;
         display: -webkit-box;
         //   -webkit-line-clamp: 2;
         -webkit-box-orient: vertical;
        }
        //  .name-content {
        //   width: 70%;
        //  }
       }
      }
      &:hover {
       background: #e4e4e481;
      }
     }
    }
   }
   </style>
   