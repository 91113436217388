<template>
 <div class="main-inner-blk">
  <div class="filter-container">
   <div class="filter-blk">
    <v-text-field placeholder="Name / Mobile / Trip ID/ Driver/ Vehicle / Crew ID" label="Search" dense outlined clearable hide-details v-model="filter.searchquery"></v-text-field>
   </div>
   <div class="filter-blk">
    <v-autocomplete
     :items="tripStatus"
     outlined
     dense
     placeholder="Trip Status"
     hide-details
     color="#bbbbbb"
     @change="setFilter"
     multiple
     item-text="name"
     item-value="id"
     v-model="filter.status"
     single-line
    >
    </v-autocomplete>
   </div>
  </div>

  <div class="content-container">
   <v-data-table :items="items" :headers="headers" item-value="id" height="calc(100vh - 240px)" hide-default-footer class="sticky-header">
    <template v-slot:body="{ items, headers }">
     <tbody>
      <tr v-for="(trip, index) in 5" :key="index">
       <td>231756</td>
       <td>
        <div class="info-block cab-block">
         <span v-for="(employee, employeeIndex) in 3" :key="employeeIndex">
          test name - 9074145470
          <!-- <i class="fas fa-user-circle"></i> {{ employee.name }} -
          {{ employee.mobile }} -->
         </span>
        </div>
       </td>

       <td>
        <div class="info-block cab-block">
         <span>test name - 9074145470 - KL42T3016</span>
         <!-- <span v-if="Object.keys(data.servicePartner).length > 0 && user.settings.isWhiteLabel">{{ data.servicePartner.name }}</span>
         <span v-if="Object.keys(data.driver).length > 0 && Object.keys(data.vehicle).length > 0">
          <i class="fas fa-taxi"></i> {{ data.driver.name }} {{ data.driver.mobile }} - {{ data.vehicle.vehicleno }}</span
         > -->
        </div>
       </td>

       <td>
        <TripStatusLog></TripStatusLog>
       </td>

       <td>
        <div class="alert-block">
         <div class="alert-image">
          <span class="notification-block" v-if="trip.notificationData.sosCount > 0">{{ trip.notificationData.sosCount }}</span>

          <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="var(--icon-color)" @click="fetchTripLog(data, 'sos')" class="fas">
           <path
            d="M202.23-202.23q-54.69-54.31-84.96-125.25Q87-398.41 87-479.58q0-81.8 30.27-152.65 30.27-70.85 84.96-125.54L263-697q-42.13 42.2-66.06 97.82Q173-543.55 173-480.26q0 64.26 24 119.76t66 97.5l-60.77 60.77Zm113-113q-32.69-32.31-50.46-74.24Q247-431.4 247-479.56q0-48.82 17.77-90.67t50.46-74.54L376-584q-20.13 20.19-31.56 46.81Q333-510.58 333-480.29t11.44 57.12Q355.87-396.35 376-376l-60.77 60.77ZM480-407q-29.9 0-51.45-21.55Q407-450.1 407-480q0-29.9 21.55-51.45Q450.1-553 480-553q29.9 0 51.45 21.55Q553-509.9 553-480q0 29.9-21.55 51.45Q509.9-407 480-407Zm164.77 91.77L584-376q20.13-20.34 31.56-47.16Q627-449.97 627-480.49q0-30.51-11.44-56.97Q604.13-563.92 584-584l60.77-60.77q32.69 32.69 50.46 74.43Q713-528.6 713-480q0 48.32-17.77 90.39t-50.46 74.38Zm113 113L697-263q42.13-42.2 66.06-97.82Q787-416.45 787-479.74q0-63.88-24-119.38-24-55.5-66-97.88l60.77-60.77q54.69 54.69 84.96 125.44Q873-561.59 873-480q0 81.32-30.27 152.39t-84.96 125.38Z"
           />
          </svg>
         </div>
         <div class="alert-image">
          <span class="notification-block" v-if="trip.notificationData.speedLimitCount > 0">{{ trip.notificationData.speedLimitCount }}</span>

          <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="var(--icon-color)" @click="fetchTripLog(data, 'speed')" class="fas">
           <path
            d="M418-340q24 24 62 23.5t56-27.5l224-336-336 224q-27 18-28.5 55t22.5 61Zm62-460q59 0 113.5 16.5T696-734l-76 48q-33-17-68.5-25.5T480-720q-133 0-226.5 93.5T160-400q0 42 11.5 83t32.5 77h552q23-38 33.5-79t10.5-85q0-36-8.5-70T766-540l48-76q30 47 47.5 100T880-406q1 57-13 109t-41 99q-11 18-30 28t-40 10H204q-21 0-40-10t-30-28q-26-45-40-95.5T80-400q0-83 31.5-155.5t86-127Q252-737 325-768.5T480-800Zm7 313Z"
           />
          </svg>
         </div>
         <div class="alert-image">
          <span class="notification-block" v-if="trip.notificationData.suddenBreakCount > 0">{{ trip.notificationData.suddenBreakCount }}</span>
          <svg
           xmlns="http://www.w3.org/2000/svg"
           enable-background="new 0 0 24 24"
           height="20px"
           viewBox="0 0 24 24"
           width="20px"
           fill="var(--icon-color)"
           @click="fetchTripLog(data, 'suddentbreak')"
           class="fas"
          >
           <g>
            <rect fill="none" height="24" width="24" />
           </g>
           <g>
            <g>
             <g>
              <circle cx="6.5" cy="15.5" r="1.5" />
             </g>
             <g>
              <circle cx="15.5" cy="15.5" r="1.5" />
             </g>
             <g>
              <path
               d="M18,13v5H4v-5H18c-1.91,0-3.63-0.76-4.89-2H4.81l1.04-3h5.44C11.1,7.37,11,6.7,11,6s0.1-1.37,0.29-2H8v2H5.5 C4.84,6,4.29,6.42,4.08,7.01L2,13v8c0,0.55,0.45,1,1,1h1c0.55,0,1-0.45,1-1v-1h12v1c0,0.55,0.45,1,1,1h1c0.55,0,1-0.45,1-1v-8 l-0.09-0.27C19.3,12.9,18.66,13,18,13z"
              />
             </g>
             <g>
              <path d="M18,1c-2.76,0-5,2.24-5,5s2.24,5,5,5s5-2.24,5-5S20.76,1,18,1z M18.5,9h-1V8h1V9z M18.5,7h-1V3h1V7z" />
             </g>
            </g>
           </g>
          </svg>
         </div>
         <div class="alert-image">
          <span class="notification-block" v-if="data.notificationData.longWaitingCount > 0">{{ data.notificationData.longWaitingCount }}</span>
          <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 384 512" width="20px " fill="var(--icon-color)" @click="fetchTripLog(data, 'longwaiting')" class="fas">
           <path
            d="M32 0C14.3 0 0 14.3 0 32S14.3 64 32 64l0 11c0 42.4 16.9 83.1 46.9 113.1L146.7 256 78.9 323.9C48.9 353.9 32 394.6 32 437l0 11c-17.7 0-32 14.3-32 32s14.3 32 32 32l32 0 256 0 32 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l0-11c0-42.4-16.9-83.1-46.9-113.1L237.3 256l67.9-67.9c30-30 46.9-70.7 46.9-113.1l0-11c17.7 0 32-14.3 32-32s-14.3-32-32-32L320 0 64 0 32 0zM288 437l0 11L96 448l0-11c0-25.5 10.1-49.9 28.1-67.9L192 301.3l67.9 67.9c18 18 28.1 42.4 28.1 67.9z"
           />
          </svg>
         </div>
        </div>
       </td>
      </tr>
     </tbody>
    </template>
   </v-data-table>

   <v-pagination v-model="pagination" :length="parseInt(rowCount / 20) + 1"></v-pagination>
  </div>
 </div>
</template>

<script>
import axios from "@/axios/axios-global";
import TripStatusLog from "./TripStatusLog.vue";

export default {
 name: "RealTime",
 components: { TripStatusLog },
 data() {
  return {
   tripStatus: [
    { id: 5, name: "On the way" },
    { id: 6, name: "Arrived" },
    { id: 8, name: "Started" },
   ],
   filter: {
    status: [],
    searchquery: "",
   },
   pagination: 1,

   headers: [
    { text: "Trip ID", value: "tripid" },

    { text: "User", value: "user" },
    { text: "Driver", value: "licence" },
    { text: "Trip Status", value: "status" },
    { text: "Alerts", value: "alert" },
   ],
   tripList: [],
   rowCount: 0,
  };
 },
 methods: {
  setFilter() {
   console.log(this.status);
  },
  mounted() {
   console.log("mounted");
   this.getRealTime();
  },
  async getRealTime() {
   const payload = {
    filter: this.filter,
    page: this.pagination,
   };
   console.log(payload, "payload");
   const response = await axios.post("trip/detail/logs", payload);
   console.log(response);
   this.tripList = this.allTrips;
   this.rowCount = response.data.rowCount;
  },
 },
};
</script>

<style lang="scss" scoped>
.filter-container {
 display: flex;
 background-color: white;
 padding: 20px;
 border-radius: 5px;
 gap: 10px;
 box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
 .filter-blk {
  width: 25%;
 }
}

.content-container {
 margin-top: 20px;
 padding: 5px;
 background: white;
 .info-block {
  display: flex;
  flex-direction: column;
  padding: 5px 0;
  font-size: 13px;
 }

 .alert-block {
  display: flex;
  column-gap: 10px;
  width: 10%;

  @media screen and (max-width: 1600px) {
   width: 15%;
  }

  .alert-image {
   position: relative;
   display: flex;
   align-items: flex-end;
   padding: 8px;
   background-color: rgb(44, 44, 44);
   border-radius: 5px;
   display: flex;
   align-items: center;
   justify-content: center;
   transition-duration: 0.3s;
   --icon-color: #ffffff;
   // --icon-size: 24px;

   // @media screen and (max-width: 1500px) {
   //   padding: 1px;
   //   --icon-size: 20px;
   // }
  }

  .notification-block {
   position: absolute;
   top: -7px;
   right: -6px;
   background: red;
   color: white;
   font-size: 8px;
   min-height: 22px;
   min-width: 22px;
   border-radius: 50%;
   display: flex;
   align-items: center;
   justify-content: center;
   font-weight: bold;
   font-size: 12px;
  }

  .fas {
   cursor: pointer;
  }

  .image-block {
   width: 25px;
   height: 25px;

   @media screen and (max-width: 1320px) {
    width: 20px;
    height: 20px;
   }
  }
 }
}
</style>
